import React, { useState } from "react";

import { Routes, Route } from "react-router-dom";

import Food from "./Food/Food";
import Text from "./Texts/Text";
import ClientsRes from "./ClientsRes/ClientsRes";
import HotelRes from "./ClientsRes/HotelRes";
import Specials from "./Specials/Specials";
import Report from "./Report";
import Night from "./Night/Night";
import Brunch from "./Brunch/Brunch";
import AvisEdit from "./AvisEdit/AvisEdit";
import Sidebar from "./Navigation/Sidebar";
import Topbar from "./Navigation/Topbar";

export default function Admin() {
	const [toggleMenu, setToggleMenu] = useState(true);

	return (
		<div className="min-h-screen dark:bg-slate-800">
			<Routes>
				<Route
					path="/"
					element={
						<>
							<div className="flex items-start justify-start">
								<Sidebar toggleMenu={toggleMenu}  setToggleMenu={setToggleMenu}/>
								<div className="flex w-full flex-col">
									<Topbar setToggleMenu={setToggleMenu} />
									<Food />
								</div>
							</div>
						</>
					}
				/>
				<Route
					path="/brunch"
					element={
						<>
							<div className="flex items-start justify-start">
								<Sidebar toggleMenu={toggleMenu}  setToggleMenu={setToggleMenu}/>
								<div className="flex w-full flex-col">
									<Topbar setToggleMenu={setToggleMenu} />
									<Brunch />
								</div>
							</div>
						</>
					}
				/>
				<Route
					path="/soir"
					element={
						<>
							<div className="flex items-start justify-start">
								<Sidebar toggleMenu={toggleMenu}  setToggleMenu={setToggleMenu}/>
								<div className="flex w-full flex-col">
									<Topbar setToggleMenu={setToggleMenu} />
									<Night />
								</div>
							</div>
						</>
					}
				/>
				<Route
					path="/evenements"
					element={
						<>
							<div className="flex items-start justify-start">
								<Sidebar toggleMenu={toggleMenu}  setToggleMenu={setToggleMenu}/>
								<div className="flex w-full flex-col">
									<Topbar setToggleMenu={setToggleMenu} />
									<Specials />
								</div>
							</div>
						</>
					}
				/>
				<Route
					path="/textes"
					element={
						<>
							<div className="flex items-start justify-start">
								<Sidebar toggleMenu={toggleMenu}  setToggleMenu={setToggleMenu}/>
								<div className="flex w-full flex-col">
									<Topbar setToggleMenu={setToggleMenu} />
									<Text />
								</div>
							</div>
						</>
					}
				/>
				<Route
					path="/avis"
					element={
						<>
							<div className="flex items-start justify-start">
								<Sidebar toggleMenu={toggleMenu}  setToggleMenu={setToggleMenu}/>
								<div className="flex w-full flex-col">
									<Topbar setToggleMenu={setToggleMenu} />
									<AvisEdit />
								</div>
							</div>
						</>
					}
				/>
				<Route
					path="/restaurant"
					element={
						<>
							<div className="flex items-start justify-start">
								<Sidebar toggleMenu={toggleMenu}  setToggleMenu={setToggleMenu}/>
								<div className="flex w-full flex-col">
									<Topbar setToggleMenu={setToggleMenu} />
									<ClientsRes />
								</div>
							</div>
						</>
					}
				/>
				<Route
					path="/hotel"
					element={
						<>
							<div className="flex items-start justify-start">
								<Sidebar toggleMenu={toggleMenu}  setToggleMenu={setToggleMenu}/>
								<div className="flex w-full flex-col">
									<Topbar setToggleMenu={setToggleMenu} />
									<HotelRes />
								</div>
							</div>
						</>
					}
				/>
				<Route
					path="/rapport"
					element={
						<>
							<div className="flex items-start justify-start">
								<Sidebar toggleMenu={toggleMenu}  setToggleMenu={setToggleMenu}/>
								<div className="flex w-full flex-col">
									<Topbar setToggleMenu={setToggleMenu} />
									<Report />
								</div>
							</div>
						</>
					}
				/>
			</Routes>
		</div>
	);
}
