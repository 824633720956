import { useState, useEffect } from "react";

import {
	getDocs,
	collection,
	doc,
	updateDoc,
	query,
	where,
} from "firebase/firestore";
import { db } from "../../../firebase";

export default function ToggleSpecial() {
	const [specialDoc, setSpecialDoc] = useState();

	const [specialName, setSpecialName] = useState("");
	const [specialPrice, setSpecialPrice] = useState();

	const [updated, setUpdated] = useState(false);

	const webContent = collection(db, "website_content");
	const q = query(webContent, where("type", "==", "brunch"));

	const submitUpdate = async e => {
		e.preventDefault();

		var ID = specialDoc[0].id;

		const chosenDoc = doc(db, "website_content", ID);
		await updateDoc(chosenDoc, {
			type: "brunch",
			specialName: specialName,
			specialPrice: specialPrice,
		});

		e.target.reset();
		setUpdated(true);
	};

	useEffect(() => {
		const getMain = async () => {
			const data = await getDocs(q);
			setSpecialDoc(
				data.docs.map(doc => ({
					...doc.data(),
					id: doc.id,
				}))
			);
		};

		getMain();
	}, []);

	if (updated) {
		setTimeout(() => {
			setUpdated(false);
		}, 3000);
	}

	return (
		<form onSubmit={submitUpdate} className="mb-6 flex w-full flex-col items-center justify-center">
			<h2 className="my-4 self-start text-2xl font-semibold dark:text-white">Renommer menu brunch</h2>
			<div className="flex w-full items-center justify-between">
				<input
					type="text"
					placeholder="Nom carte"
					className="my-2 w-4/5 rounded-lg border bg-white p-3 text-sm dark:border-slate-500 dark:bg-gray-700 dark:text-white"
					onChange={e => setSpecialName(e.target.value)}
					required
				/>
				<input
					className="my-2 w-1/6 rounded-lg border bg-white p-3 text-sm dark:border-slate-500 dark:bg-gray-700 dark:text-white"
					onChange={e => setSpecialPrice(e.target.value)}
					type="number"
					min="0"
					step="0.01"
					required
					placeholder="Prix"
				/>
			</div>
			<button
				type="submit"
				className={
					updated
						? "mt-4 w-full rounded-full bg-green-500 p-3 text-sm font-medium"
						: "mt-4 w-full rounded-full bg-yellow-500 p-3 text-sm font-medium"
				}
			>
				{updated ? "Menu renommé" : "Envoyer"}
			</button>
		</form>
	);
}
