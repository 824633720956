import { useState, useMemo, useEffect } from "react";

import DataTable from "react-data-table-component";
import ReactPaginate from "react-paginate";

import { GoChevronRight, GoChevronLeft } from "react-icons/go";
import { BsChevronDown } from "react-icons/bs";
import { BiMailSend } from "react-icons/bi";
import { MdDeleteForever } from "react-icons/md";

import { db } from "../../../firebase";
import { onSnapshot, deleteDoc, doc, collection, updateDoc, query, orderBy } from "firebase/firestore";

import Reply from "./Reply";

const ExpandableTable = ({ data }) => {
	return (
		<div className="flex w-full flex-col space-y-1 bg-slate-50 p-4 pl-8 transition-all dark:bg-slate-600">
			<div className="flex flex-col text-sm">
				<span className="mr-2 font-semibold text-teal-600 dark:text-teal-500">Review :</span>
				<span className="dark:text-white">{data.review ? data.review : "Aucune"}</span>
			</div>
		</div>
	);
};

export default function AvisEdit() {
	const [allReviews, setAllReviews] = useState([]);
	const [currentPage, setCurrentPage] = useState(0);
	const [searchValue, setSearchValue] = useState("");
	const [showReply, setShowReply] = useState(false);
	const [singleReview, setSingleReview] = useState();
	const [email, setEmail] = useState();

	const reviewsDB = collection(db, "avis");

	const showPopup = (review, email) => {
		setShowReply(true);
		setSingleReview(review);
		setEmail(email);
	};

	const handleDelete = async docID => {
		const chosenDoc = doc(db, "avis", docID);
		await deleteDoc(chosenDoc);
	};

	const columns = [
		{
			name: "Client",
			sortable: true,
			reorder: true,
			selector: row => row.name,
		},
		{
			name: "Experience",
			sortable: true,
			selector: row => row.exp,
			reorder: true,
		},
		{
			name: "Email",
			sortable: true,
			minWidth: "250px",
			selector: row => row.email,
			reorder: true,
		},
		{
			name: "Date",
			sortable: true,
			reorder: true,
			minWidth: "180px",
			selector: row => row.date,
		},
		{
			name: "actions",
			sortable: false,
			cell: row => {
				return (
					<div className="flex items-center space-x-3">
						<button
							onClick={() => showPopup(row.id, row.email)}
							className="text-slate-600 transition-all duration-200 hover:text-teal-600 dark:text-slate-400 dark:hover:text-teal-500"
						>
							<BiMailSend className="text-2xl" />
						</button>
						<button
							onClick={() => handleDelete(row.id)}
							className="text-slate-600 transition-all duration-200 hover:text-teal-600 dark:text-slate-400 dark:hover:text-teal-500"
						>
							<MdDeleteForever className="text-2xl" />
						</button>
					</div>
				);
			},
		},
	];

	const q = query(reviewsDB, orderBy("created", "desc"));

	useEffect(() => {
		const unsub = onSnapshot(q, snapshot => {
			setAllReviews(snapshot.docs.map(doc => ({ ...doc.data(), id: doc.id })));
		});

		return unsub;
	}, []); //eslint-disable-line

	function convertArrayOfObjectsToCSV(array) {
		let result;
		const columnDelimiter = ",";
		const lineDelimiter = "\n";
		const keys = Object.keys(allReviews[0]);

		result = "";
		result += keys.join(columnDelimiter);
		result += lineDelimiter;

		array.forEach(item => {
			let ctr = 0;
			keys.forEach(key => {
				if (ctr > 0) result += columnDelimiter;

				result += item[key];

				ctr++;
			});
			result += lineDelimiter;
		});

		return result;
	}

	function downloadCSV(array) {
		const link = document.createElement("a");
		let csv = convertArrayOfObjectsToCSV(array);
		if (csv == null) return;

		const filename = "export.csv";

		if (!csv.match(/^data:text\/csv/i)) {
			csv = `data:text/csv;charset=utf-8,${csv}`;
		}

		link.setAttribute("href", encodeURI(csv));
		link.setAttribute("download", filename);
		link.click();
	}

	const Export = ({ onExport }) => (
		<button
			className="rounded border border-slate-400 p-2 px-5 text-sm font-medium text-slate-600 transition-all duration-200 hover:bg-slate-100"
			onClick={e => onExport(e.target.value)}
		>
			Export CSV
		</button>
	);

	const actionsMemo = useMemo(() => <Export onExport={() => downloadCSV(data)} />, []); //eslint-disable-line

	const filterReviews = useMemo(() => {
		return allReviews.filter(item => {
			if (!item.email) {
				return item.name.toLowerCase().includes(searchValue.toLowerCase());
			} else {
				return (
					item.name.toLowerCase().includes(searchValue.toLowerCase()) ||
					item.email.toLowerCase().includes(searchValue.toLowerCase())
				);
			}
		});
	}, [searchValue, allReviews]);

	const handlePagination = page => {
		setCurrentPage(page.selected);
	};

	const CustomPagination = () => (
		<div className="mt-6 flex w-full items-center justify-end px-7">
			<ReactPaginate
				pageClassName="font-semibold text-sm dark:text-white transition-all duration-200 px-3 py-1 w-[28px] h-[28px] text-center flex items-center justify-center"
				activeClassName="bg-teal-600 transition-all duration-200 rounded-full text-white px-3 py-1"
				containerClassName="flex items-center justify-center bg-slate-200 dark:bg-slate-700 px-2 rounded-full"
				nextLabel={<GoChevronRight className="ml-2 text-lg text-teal-500" />}
				previousLabel={<GoChevronLeft className="mr-2 text-lg text-teal-500" />}
				forcePage={currentPage}
				onPageChange={page => handlePagination(page)}
				pageCount={
					searchValue.length ? Math.ceil(filterReviews.length / 15) : Math.ceil(allReviews.length / 15) || 1
				}
				breakLabel={"..."}
				pageRangeDisplayed={2}
				marginPagesDisplayed={2}
			/>
		</div>
	);

	return (
		<section className="flex w-full flex-col">
			<Reply
				id={singleReview}
				showReply={showReply}
				setShowReply={setShowReply}
				db={db}
				doc={doc}
				email={email}
				updateDoc={updateDoc}
			/>
			<div className="mb-8 flex w-full items-center justify-end px-6">
				<label htmlFor="lead" className="text-sm dark:text-white">
					Chercher
					<input
						value={searchValue}
						onChange={e => setSearchValue(e.target.value)}
						id="lead"
						type="text"
						placeholder="Nom, email"
						className="ml-3 w-[250px] rounded border p-1 px-2 outline-none focus:ring-1 focus:ring-teal-600 dark:border-slate-500 dark:bg-slate-600"
					/>
				</label>
			</div>
			<div>
				<DataTable
					noHeader
					pagination
					data={filterReviews}
					actions={actionsMemo}
					expandableRows
					selectableRows={false}
					columns={columns}
					paginationPerPage={15}
					expandOnRowClicked={true}
					className="react-dataTable"
					sortIcon={<BsChevronDown className="ml-1 p-[2px] dark:text-white" />}
					paginationComponent={CustomPagination}
					paginationDefaultPage={currentPage + 1}
					expandableRowsComponent={ExpandableTable}
				/>
			</div>
		</section>
	);
}
