import React, { useState } from "react";

import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/airbnb.css";

import flatpickr from "flatpickr";
import { French } from "flatpickr/dist/l10n/fr.js";

import { FaBed } from "react-icons/fa";

import HotelBtn from "./HotelBtn";

import { getDocs, collection, query, where } from "firebase/firestore";
import { db } from "../../../firebase";
import { MdDeleteForever } from "react-icons/md";

flatpickr.localize(French);

export default function ClientsRes() {
	const [customer, setCustomer] = useState([]);
	const [picker, setPicker] = useState("");

	const customers = collection(db, "res_hotel");
	let arr = [];

	const options = {
		year: "numeric",
		month: "long",
		day: "numeric",
	};

	const handleSubmit = async e => {
		e.preventDefault();

		if (!picker) {
			return;
		}

		let stringified = new Date(picker).toLocaleDateString("fr-FR", options);

		const q = query(customers, where("arrive", "==", stringified));
		const querySnapshot = await getDocs(q);
		querySnapshot.forEach(doc => {
			arr.push({ ...doc.data(), id: doc.id });
		});
		setCustomer(arr);
		e.target.reset();
	};

	const onDelete = product => {
		const exist = customer.find(x => x.id === product.id);
		if (exist) {
			setCustomer(customer.filter(x => x.id !== product.id));
		}
	};

	const flatpickrOptions = { mode: "single", dateFormat: "d/m/Y", ...French, firstDayOfWeek: 3 };

	return (
		<div className="relative flex h-full w-full flex-col items-center justify-start">
			<div className="flex w-full flex-col p-8">
				<form onSubmit={handleSubmit} className="flex flex-col items-center justify-center self-end">
					<div className="flex w-full items-center ">
						<div className="flex items-center">
							<label htmlFor="date" className="flex items-center text-sm dark:text-white">
								Date
								<Flatpickr
									className="ml-3 w-[250px] cursor-pointer rounded-lg border p-2 outline-none focus:ring-1 focus:ring-teal-600 dark:border-slate-500 dark:bg-slate-700"
									id="date"
									placeholder="Filtrer par date"
									value={picker}
									options={flatpickrOptions}
									onChange={date => setPicker(date)}
								/>
							</label>
							<button type="button" onClick={() => setPicker()} className="ml-3">
								<MdDeleteForever className="text-2xl dark:text-white" />
							</button>
						</div>
						<button
							type="submit"
							className="ml-5 rounded-lg border-2 border-transparent bg-green-600 p-2 text-sm font-medium text-white transition-all duration-300 hover:border-green-600 hover:bg-transparent hover:text-green-600 md:ml-0"
						>
							<i className="fa-solid fa-magnifying-glass "></i>
						</button>
					</div>
				</form>
				<div className="mt-6 grid w-full gap-6 md:grid-cols-4">
					<div className="flex cursor-default items-center justify-start rounded-xl border p-3 transition-all duration-200 hover:shadow-md dark:border-slate-500 dark:hover:shadow-lg dark:hover:shadow-teal-800">
						<span className="flex h-[50px] w-[50px] items-center justify-center rounded-full bg-teal-100">
							<FaBed className="text-2xl text-teal-700" />
						</span>
						<div className="ml-4 flex flex-col">
							<span className="text-sm font-medium tracking-wide text-slate-600 dark:text-slate-200">
								{customer.length === 0
									? "Aucune"
									: customer.map(n => <span key={n.id}>n° {n.chambre}</span>)}
							</span>
							<span className="text-xs font-semibold text-slate-400">Chambres occupées</span>
						</div>
					</div>
				</div>
			</div>
			<div className="mt-8 flex w-full flex-col items-center justify-center">
				{customer.map((x, i) => (
					<HotelBtn key={i} customer={x} onDelete={onDelete} />
				))}
			</div>
		</div>
	);
}
