import React, { useContext } from "react";
import Login from "./Components/Admin/Login";
import { Outlet } from "react-router-dom";
import { authContext } from "./Context/AuthContext";

export default function ProtectedRoute() {
	const { user } = useContext(authContext);

	return user ? <Outlet /> : <Login />;
}
