import React from "react";
import { Link, useLocation } from "react-router-dom";

import { MdMenuBook, MdTextFormat, MdStar, MdHotel } from "react-icons/md";
import { IoRestaurantSharp } from "react-icons/io5";
import { ImStatsBars } from "react-icons/im";

export default function Sidebar({ toggleMenu, setToggleMenu }) {
	const location = useLocation();

	return (
		<div
			className={
				toggleMenu
					? "fixed left-0 top-0 z-10 flex h-screen min-h-[1000px] w-[320px] min-w-[30%] flex-col items-center bg-white opacity-100 shadow-2xl transition-all duration-200 dark:bg-slate-700 lg:sticky lg:min-w-[22%] xl:w-[18%]"
					: "pointer-events-none fixed -left-full top-0 z-10 flex h-screen min-h-[1000px] min-w-[0%] flex-col items-center overflow-hidden bg-white opacity-0 shadow-2xl transition-all duration-200 dark:bg-slate-700 lg:sticky xl:w-[0%]"
			}
		>
			<div className="relative flex w-full flex-col items-start p-6">
				<span
					onClick={() => setToggleMenu(false)}
					className="absolute right-6 cursor-pointer text-3xl text-white lg:hidden"
				>
					&times;
				</span>
				<div className="flex">
					<h1 className="logo text-center text-4xl text-yellow-500 ">BBMK</h1>
					<span className="text-xs font-bold lowercase tracking-normal text-teal-600 dark:text-teal-500">v3</span>
				</div>
				<h4 className="mt-1 text-sm font-semibold uppercase tracking-[.5em] text-black dark:text-white">
					Espace Admin
				</h4>
			</div>
			<div className="mt-3 flex w-full flex-col items-start justify-start p-6">
				<div className="flex w-full flex-col items-start justify-start rounded-2xl bg-slate-100 p-6 dark:bg-slate-900">
					<div className="flex w-full flex-col items-center justify-center space-y-3 ">
						<h2 className="w-full pb-2 text-left text-lg font-semibold text-yellow-600 dark:text-yellow-500">
							Réglages Site
						</h2>
						<Link
							to="/admin"
							className={
								location.pathname === "/admin"
									? "flex w-full items-center rounded-xl bg-teal-600 py-3 px-4 text-left text-sm font-medium tracking-wide text-yellow-400 shadow-lg shadow-teal-500 transition-all duration-300 dark:shadow-teal-700"
									: "flex w-full items-center rounded-xl py-3 px-4 text-left text-sm font-semibold text-black transition-all duration-300 hover:bg-slate-200 hover:text-teal-600 dark:text-white dark:hover:bg-slate-800 dark:hover:text-teal-500"
							}
						>
							<MdMenuBook className="mr-2 text-xl" /> Menu Déjeuner
						</Link>
						<Link
							to="/admin/brunch"
							className={
								location.pathname === "/admin/brunch"
									? " flex w-full items-center rounded-xl bg-teal-600 py-3 px-4 text-left text-sm font-medium tracking-wide text-yellow-400 shadow-lg shadow-teal-500 transition-all duration-300 dark:shadow-teal-700"
									: "flex w-full items-center rounded-xl py-3 px-4 text-left text-sm font-medium text-black transition-all duration-300 hover:bg-slate-200 hover:text-teal-600 dark:text-white dark:hover:bg-slate-800 dark:hover:text-teal-500"
							}
						>
							<MdMenuBook className="mr-2 text-xl" /> Menu Brunch
						</Link>
						<Link
							to="/admin/soir"
							className={
								location.pathname === "/admin/soir"
									? " flex w-full items-center rounded-xl bg-teal-600 py-3 px-4 text-left text-sm font-medium tracking-wide text-yellow-400 shadow-lg shadow-teal-500 transition-all duration-300 dark:shadow-teal-700"
									: "flex w-full items-center rounded-xl py-3 px-4 text-left text-sm font-medium text-black transition-all duration-300 hover:bg-slate-200 hover:text-teal-600 dark:text-white dark:hover:bg-slate-800 dark:hover:text-teal-500"
							}
						>
							<MdMenuBook className="mr-2 text-xl" /> Menu Soir
						</Link>
						<Link
							to="/admin/evenements"
							className={
								location.pathname === "/admin/evenements"
									? " flex w-full items-center rounded-xl bg-teal-600 py-3 px-4 text-left text-sm font-medium tracking-wide text-yellow-400 shadow-lg shadow-teal-500 transition-all duration-300 dark:shadow-teal-700"
									: "flex w-full items-center rounded-xl py-3 px-4 text-left text-sm font-medium text-black transition-all duration-300 hover:bg-slate-200 hover:text-teal-600 dark:text-white dark:hover:bg-slate-800 dark:hover:text-teal-500"
							}
						>
							<MdMenuBook className="mr-2 text-xl" /> Menu Evenements
						</Link>

						<Link
							to="/admin/textes"
							className={
								location.pathname === "/admin/textes"
									? " flex w-full items-center rounded-xl bg-teal-600 py-3 px-4 text-left text-sm font-medium tracking-wide text-yellow-400 shadow-lg shadow-teal-500 transition-all duration-300 dark:shadow-teal-700"
									: "flex w-full items-center rounded-xl py-3 px-4 text-left text-sm font-medium text-black transition-all duration-300 hover:bg-slate-200 hover:text-teal-600 dark:text-white dark:hover:bg-slate-800 dark:hover:text-teal-500"
							}
						>
							<MdTextFormat className="mr-2 text-xl" /> Textes
						</Link>
						<Link
							to="/admin/avis"
							className={
								location.pathname === "/admin/avis"
									? " flex w-full items-center rounded-xl bg-teal-600 py-3 px-4 text-left text-sm font-medium tracking-wide text-yellow-400 shadow-lg shadow-teal-500 transition-all duration-300 dark:shadow-teal-700"
									: "flex w-full items-center rounded-xl py-3 px-4 text-left text-sm font-medium text-black transition-all duration-300 hover:bg-slate-200 hover:text-teal-600 dark:text-white dark:hover:bg-slate-800 dark:hover:text-teal-500"
							}
						>
							<MdStar className="mr-2 text-xl" />
							Avis
						</Link>
					</div>
					<div className="mt-10 flex w-full flex-col items-center justify-center space-y-2">
						<h2 className="w-full pb-2 text-left text-lg font-semibold text-yellow-600 dark:text-yellow-500">
							Réservations
						</h2>

						<Link
							to="/admin/restaurant"
							className={
								location.pathname === "/admin/restaurant"
									? " flex w-full items-center rounded-xl bg-teal-600 py-3 px-4 text-left text-sm font-medium tracking-wide text-yellow-400 shadow-lg shadow-teal-500 transition-all duration-300 dark:shadow-teal-700"
									: "flex w-full items-center rounded-xl py-3 px-4 text-left text-sm font-medium text-black transition-all duration-300 hover:bg-slate-200 hover:text-teal-600 dark:text-white dark:hover:bg-slate-800 dark:hover:text-teal-500"
							}
						>
							<IoRestaurantSharp className="mr-2 text-xl" /> Restaurant
						</Link>
						<Link
							to="/admin/hotel"
							className={
								location.pathname === "/admin/hotel"
									? " flex w-full items-center rounded-xl bg-teal-600 py-3 px-4 text-left text-sm font-medium tracking-wide text-yellow-400 shadow-lg shadow-teal-500 transition-all duration-300 dark:shadow-teal-700"
									: "flex w-full items-center rounded-xl py-3 px-4 text-left text-sm font-medium text-black transition-all duration-300 hover:bg-slate-200 hover:text-teal-600 dark:text-white dark:hover:bg-slate-800 dark:hover:text-teal-500"
							}
						>
							<MdHotel className="mr-2 text-xl" />
							Hotel
						</Link>
						<Link
							to="/admin/rapport"
							className={
								location.pathname === "/admin/rapport"
									? " flex w-full items-center rounded-xl bg-teal-600 py-3 px-4 text-left text-sm font-medium tracking-wide text-yellow-400 shadow-lg shadow-teal-500 transition-all duration-300 dark:shadow-teal-700"
									: "flex w-full items-center rounded-xl py-3 px-4 text-left text-sm font-medium text-black transition-all duration-300 hover:bg-slate-200 hover:text-teal-600 dark:text-white dark:hover:bg-slate-800 dark:hover:text-teal-500"
							}
						>
							<ImStatsBars className="mr-2 text-xl" />
							Rapport
						</Link>
					</div>
				</div>
			</div>
		</div>
	);
}
