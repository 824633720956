import React, { useState, useEffect } from "react";

import {
	MouseParallaxContainer,
	MouseParallaxChild,
} from "react-parallax-mouse";
import { Link } from "react-scroll";

import { onSnapshot, collection, query, where } from "firebase/firestore";

import { storage } from "../../firebase";
import { db } from "../../firebase";

export default function About() {
	const [text, setText] = useState();
	const [title, setTitle] = useState("");
	const [paragraph, setParagraph] = useState("");

	const typography = collection(db, "website_content");
	const q = query(typography, where("position", "==", "Cuisine"));

	useEffect(() => {
		const unsub = onSnapshot(q, snapshot => {
			setText(snapshot.docs.map(doc => ({ ...doc.data(), id: doc.id })));
		});

		return unsub;
	}, []);

	useEffect(() => {
		if (text) {
			setTitle(text[0].title);
			setParagraph(text[0].paragraph);
		}
	}, [text]);

	return (
		<section
			id="carte"
			className="flex w-full items-center justify-center overflow-x-hidden overflow-y-hidden"
		>
			<div className="container mx-auto my-24 py-20 flex flex-col items-center justify-between px-4 md:flex-row lg:px-10">
				<div className="w-[90%] md:w-[50%]" data-aos="fade-down">
					<MouseParallaxContainer
						containerStyles={{
							position: "relative",
							overflow: "visible",
						}}
						resetOnLeave
					>
						<MouseParallaxChild factorX={0.1} factorY={0.15}>
							<img
								src="img/photos/img_15.webp"
								alt="Food"
								className="pointer-events-none w-full rounded-3xl"
							/>
						</MouseParallaxChild>
						<MouseParallaxChild
							className="absolute top-[20px] left-[20px] -z-[1] h-full w-full rounded-3xl bg-black/5 md:top-[30px] md:left-[30px]"
							factorX={0.4}
							factorY={0.6}
						></MouseParallaxChild>
						<MouseParallaxChild
							className="absolute -top-[25px] -left-[25px] h-full w-full rounded-3xl  border-[6px] border-yellow-500 bg-transparent"
							factorX={0.15}
							factorY={0.2}
						></MouseParallaxChild>
					</MouseParallaxContainer>
				</div>

				<div
					data-aos="fade-left"
					className="relative mt-24 flex w-full flex-col items-center justify-center text-center md:mt-0 md:w-[45%]"
				>
					<span className="logo pointer-events-none absolute -top-16 text-[100px] tracking-wider text-gray-500/5 md:-top-[50%] md:text-[200px]">
						BBMK
					</span>
					<h2 className="flex items-center justify-center text-4xl font-bold capitalize">
						{title}
					</h2>
					<div className="my-6 flex w-full items-center justify-center space-x-3">
						<div className="h-3 w-3 rounded-full bg-yellow-500"></div>
						<div className="h-3 w-3 rounded-full bg-yellow-500"></div>
						<div className="h-3 w-3 rounded-full bg-yellow-500"></div>
					</div>
					<p className=" leading-relaxed">{paragraph}</p>
					<div className="mt-8 flex w-full flex-col items-center justify-center space-x-0 md:flex-row md:space-x-4">
						<Link
							className="w-60 cursor-pointer rounded-full border-2 border-transparent bg-teal-700 py-2 text-sm font-semibold uppercase text-white shadow-lg shadow-teal-800 transition-all duration-200 hover:border-teal-600 hover:bg-transparent hover:text-teal-600 hover:shadow-none"
							duration={800}
							delay={100}
							smooth={true}
							to="menu"
						>
							Notre carte
						</Link>
						{/*<Link
							to="/emporter"
							className="mt-3 md:mt-0 w-60 py-2 rounded-full bg-yellow-500 text-black border-2 border-transparent hover:bg-transparent hover:text-yellow-500 hover:border-yellow-500 transition-all duration-300"
						>
							Commandez à emporter
						</Link> */}
					</div>
				</div>
			</div>
		</section>
	);
}
