import { useState, useEffect } from "react";

import { getDocs, collection, doc, updateDoc, query, where } from "firebase/firestore";
import { db } from "../../../firebase";

export default function ToggleSpecial() {
	const [specialDoc, setSpecialDoc] = useState();

	const [activeSpecial, setActiveSpecial] = useState(false);
	const [specialName, setSpecialName] = useState("");

	const [updated, setUpdated] = useState(false);

	const webContent = collection(db, "website_content");
	const q = query(webContent, where("type", "==", "evenement"));

	const submitUpdate = async e => {
		e.preventDefault();

		var ID = specialDoc[0].id;

		const chosenDoc = doc(db, "website_content", ID);
		await updateDoc(chosenDoc, {
			type: "evenement",
			evenMenu: activeSpecial,
			evenName: specialName,
		});

		e.target.reset();
		setUpdated(true);
	};

	useEffect(() => {
		const getMain = async () => {
			const data = await getDocs(q);
			setSpecialDoc(
				data.docs.map(doc => ({
					...doc.data(),
					id: doc.id,
				}))
			);
		};

		getMain();
	}, []);

	if (updated) {
		setTimeout(() => {
			setUpdated(false);
		}, 3000);
	}

	return (
		<form onSubmit={submitUpdate} className="mb-6 flex w-full flex-col items-center justify-center">
			<h2 className="my-4 self-start text-2xl font-semibold dark:text-white">Activer menu évenementiel</h2>
			<input
				type="text"
				placeholder="Nom carte (exemple: Carte de Noël)"
				className="my-2 w-full rounded-lg border bg-white p-3 text-sm dark:border-slate-500 dark:bg-gray-700 dark:text-white"
				onChange={e => setSpecialName(e.target.value)}
			/>
			<div className="my-2 space-x-5">
				<label htmlFor="actif">
					<input
						type="radio"
						value={true}
						onChange={() => setActiveSpecial(true)}
						name="toggleMenu"
						id="actif"
						required
					/>
					<span className="ml-2 dark:text-white">Menu spécial on</span>
				</label>

				<label htmlFor="desactif">
					<input
						type="radio"
						value={false}
						onChange={() => setActiveSpecial(false)}
						name="toggleMenu"
						id="desactif"
					/>
					<span className="ml-2 dark:text-white">Menu spécial off</span>
				</label>
			</div>
			<button
				type="submit"
				className={
					updated
						? "mt-4 w-full rounded-full bg-green-500 p-3 text-sm font-medium"
						: "mt-4 w-full rounded-full bg-yellow-500 p-3 text-sm font-medium"
				}
			>
				{updated ? "Menu Ajouté au site" : "Envoyer"}
			</button>
		</form>
	);
}
