import { useState, useEffect } from "react";
import { getDocs, collection, query, where } from "firebase/firestore";
import { db } from "../../firebase";

export default function Restaurant() {
	const [allItems, setAllItems] = useState([]);
	const [soirItems, setSoirItems] = useState([]);
	const [brunch, setBrunch] = useState([]);
	const [allSpecialItems, setAllSpecialItems] = useState([]);

	const [menuDoc, setMenuDoc] = useState();
	const [menuTitle, setMenuTitle] = useState("");

	const [soirDoc, setSoirDoc] = useState();
	const [soirTitle, setSoirTitle] = useState("");

	const [brunchDoc, setBrunchDoc] = useState();
	const [brunchTitle, setBrunchTitle] = useState("");
	const [brunchPrice, setBrunchPrice] = useState("");

	const [specialDoc, setSpecialDoc] = useState();
	const [specialActive, setSpecialActive] = useState(false);
	const [specialTitle, setSpecialTitle] = useState("");

	const [categorieDej, setCategorieDej] = useState([]);
	const [categorieSoir, setCategorieSoir] = useState([]);
	const [categorieBrunch, setCategorieBrunch] = useState([]);
	const [categorieEvenement, setCategorieEvenement] = useState([]);

	const [tab, setTab] = useState(0);
	const [menuSwitch, setMenuSwitch] = useState(0);

	const menu = collection(db, "menu_dejeuner");
	const menuSoir = collection(db, "menu_soir");
	/* const menuBrunch = collection(db, "menu_brunch"); */
	const specialMenu = collection(db, "menu_evenement");
	const webContent = collection(db, "website_content");

	const q = query(menu);
	const q2 = query(menuSoir);
	const q3 = query(specialMenu);
	/* const q11 = query(menuBrunch); */

	const q4 = query(webContent, where("type", "==", "soir"));
	const q5 = query(webContent, where("type", "==", "mainMenu"));
	const q6 = query(webContent, where("type", "==", "evenement"));
	/* const q7 = query(webContent, where("type", "==", "brunch")); */

	const q8 = query(webContent, where("type", "==", "categorie_dejeuner"));
	const q9 = query(webContent, where("type", "==", "categorie_soir"));
	const q10 = query(webContent, where("type", "==", "categorie_evenement"));
	/* const q12 = query(webContent, where("type", "==", "categorie_brunch")); */

	useEffect(() => {
		const getMain = async () => {
			const data = await getDocs(q);
			setAllItems(data.docs.map(doc => ({ ...doc.data(), id: doc.id })));
		};

		getMain();
	}, []);

/* 	useEffect(() => {
		const getMain = async () => {
			const data = await getDocs(q11);
			setBrunch(data.docs.map(doc => ({ ...doc.data(), id: doc.id })));
		};

		getMain();
	}, []); */

	useEffect(() => {
		const getMain = async () => {
			const data = await getDocs(q2);
			setSoirItems(data.docs.map(doc => ({ ...doc.data(), id: doc.id })));
		};

		getMain();
	}, []);

	useEffect(() => {
		const getMain = async () => {
			const data = await getDocs(q5);
			setMenuDoc(data.docs.map(doc => ({ ...doc.data(), id: doc.id })));
		};

		getMain();
	}, []);

	useEffect(() => {
		const getMain = async () => {
			const data = await getDocs(q4);
			setSoirDoc(data.docs.map(doc => ({ ...doc.data(), id: doc.id })));
		};

		getMain();
	}, []);

/* 	useEffect(() => {
		const getMain = async () => {
			const data = await getDocs(q7);
			setBrunchDoc(data.docs.map(doc => ({ ...doc.data(), id: doc.id })));
		};

		getMain();
	}, []); */

	useEffect(() => {
		const getMain2 = async () => {
			const data = await getDocs(q6);
			setSpecialDoc(data.docs.map(doc => ({ ...doc.data(), id: doc.id })));
		};

		getMain2();
	}, []);

	useEffect(() => {
		const getMain3 = async () => {
			const data = await getDocs(q9);
			setCategorieSoir(data.docs.map(doc => ({ ...doc.data(), id: doc.id })));
		};

		getMain3();
	}, []);

/* 	useEffect(() => {
		const getMain3 = async () => {
			const data = await getDocs(q12);
			setCategorieBrunch(data.docs.map(doc => ({ ...doc.data(), id: doc.id })));
		};

		getMain3();
	}, []); */

	useEffect(() => {
		const getMain3 = async () => {
			const data = await getDocs(q8);
			setCategorieDej(data.docs.map(doc => ({ ...doc.data(), id: doc.id })));
		};

		getMain3();
	}, []);

	console.log(categorieDej)

	useEffect(() => {
		const getMain3 = async () => {
			const data = await getDocs(q10);
			setCategorieEvenement(data.docs.map(doc => ({ ...doc.data(), id: doc.id })));
		};

		getMain3();
	}, []);

	useEffect(() => {
		if (specialDoc) {
			setSpecialActive(specialDoc[0].evenMenu);
			setSpecialTitle(specialDoc[0].evenName);
		}
	}, [specialDoc]);

	if (specialActive) {
		const getMain = async () => {
			const data = await getDocs(q3);
			setAllSpecialItems(data.docs.map(doc => ({ ...doc.data(), id: doc.id })));
		};

		getMain();
	}

	useEffect(() => {
		if (menuDoc) {
			setMenuTitle(menuDoc[0].mainName);
		}
	}, [menuDoc]);

	useEffect(() => {
		if (soirDoc) {
			setSoirTitle(soirDoc[0].specialName);
		}
	}, [soirDoc]);

/* 	useEffect(() => {
		if (brunchDoc) {
			setBrunchTitle(brunchDoc[0].specialName);
			setBrunchPrice(brunchDoc[0].specialPrice);
		}
	}, [brunchDoc]); */

	if (categorieDej.length < 1) return "";

	return (
		<section id="menu" className="relative w-full bg-black">
			<div className="absolute top-0 left-0 z-10 h-full w-full bg-black/70"></div>
			<div className="container relative z-10 mx-auto flex flex-col justify-center px-4 py-24 lg:px-10">
				<div className="flex w-full flex-col">
					<h1 className="logo flex max-w-full flex-col items-center justify-center self-center text-center text-[2.3em] tracking-wider md:flex-row">
						<button
							onClick={() => setMenuSwitch(0)}
							className={
								menuSwitch === 0
									? "rounded-xl border-2 border-yellow-500 px-3 py-1 text-yellow-500 transition-all duration-300"
									: "rounded-xl border-2 border-transparent px-2 py-1 text-gray-400 opacity-40 transition-all duration-300 hover:bg-black/30 hover:text-teal-500 hover:opacity-100"
							}
						>
							{menuTitle}
						</button>
						<button
							onClick={() => setMenuSwitch(1)}
							className={
								menuSwitch === 1
									? "rounded-xl border-2 border-yellow-500 px-3 py-1 text-yellow-500 transition-all duration-300"
									: "rounded-xl border-2 border-transparent px-2 py-1 text-gray-400 opacity-40 transition-all duration-300 hover:bg-black/30 hover:text-teal-500 hover:opacity-100"
							}
						>
							{soirTitle}
						</button>
						{/* <button
							onClick={() => setMenuSwitch(2)}
							className={
								menuSwitch === 2
									? "rounded-xl border-2 border-yellow-500 px-3 py-1 text-yellow-500 transition-all duration-300"
									: "rounded-xl border-2 border-transparent px-2 py-1 text-gray-400 opacity-40 transition-all duration-300 hover:bg-black/30 hover:text-teal-500 hover:opacity-100"
							}
						>
							{brunchTitle}
						</button> */}
						{specialActive ? (
							<>
								<button
									onClick={() => setMenuSwitch(3)}
									className={
										menuSwitch === 3
											? "border-b-4 border-yellow-500 p-3 text-yellow-500 transition-all duration-300"
											: "border-b-4 p-3 text-gray-400 opacity-40 transition-all duration-300"
									}
								>
									{specialTitle}
								</button>
							</>
						) : (
							""
						)}
					</h1>
					{menuSwitch === 0 && (
						<>
							<div className="mt-16 mb-8 flex w-full items-center justify-between space-x-10 overflow-x-scroll pb-8 text-white md:mb-16 md:justify-center md:overflow-x-visible md:pb-0">
								{categorieDej[0].categories.map((cat, i) => (
									<button
										key={i}
										className={tab === i ? "activeTab" : "tab"}
										onClick={() => setTab(i)}
									>
										{cat}
									</button>
								))}
							</div>
							<div className="flex flex-col items-start justify-start space-y-6 overflow-x-hidden md:grid md:grid-cols-2 md:justify-items-center md:gap-10 md:gap-x-96 md:space-y-0">
								{categorieDej[0].categories.map((cat, i) => {
									return (
										tab === i &&
										allItems
											.filter(x => x.categorie === cat)
											.map(item => (
												<div
													key={item.id}
													className="relative flex w-full items-start justify-between overflow-hidden rounded-xl bg-teal-600/20 p-6 text-white backdrop-blur-md"
													data-aos="fade-left"
												>
													<div className="absolute -right-8 -bottom-8 h-[50px] w-[50px] rotate-45 bg-yellow-500 shadow-lg"></div>
													<div className="w-full">
														<h3 className="mb-1 flex w-full items-center justify-between text-lg font-semibold uppercase tracking-wide text-yellow-500">
															<div className="flex items-center">
																<div className="mr-2 h-[10px] w-[10px] rounded-full bg-yellow-500"></div>
																{item.name}
															</div>
															<span className="text-base font-normal tracking-normal text-white">
																{parseFloat(item.price).toFixed(2)} €
															</span>
														</h3>

														<p className="font-light">{item.description}</p>
													</div>
												</div>
											))
									);
								})}
							</div>
						</>
					)}
					{menuSwitch === 1 && (
						<>
							<div className="mt-16 mb-8 flex w-full items-center justify-between space-x-10 overflow-x-scroll pb-8 text-white md:mb-16 md:justify-center md:overflow-x-visible md:pb-0">
								{categorieSoir[0].categories.map((cat, i) => (
									<button
										key={i}
										className={tab === i ? "activeTab" : "tab"}
										onClick={() => setTab(i)}
									>
										{cat}
									</button>
								))}
							</div>
							<div className="flex flex-col items-start justify-start space-y-6 overflow-x-hidden md:grid md:grid-cols-2 md:justify-items-center md:gap-10 md:gap-x-96 md:space-y-0">
								{categorieSoir[0].categories.map((cat, i) => {
									return (
										tab === i &&
										soirItems
											.filter(x => x.categorie === cat)
											.map(item => (
												<div
													key={item.id}
													className="relative flex w-full items-start justify-between overflow-hidden rounded-xl bg-teal-600/20 p-6 text-white backdrop-blur-md"
													data-aos="fade-left"
												>
													<div className="absolute -right-8 -bottom-8 h-[50px] w-[50px] rotate-45 bg-yellow-500 shadow-lg"></div>
													<div className="w-full">
														<h3 className="mb-1 flex w-full items-center justify-between text-lg font-semibold uppercase tracking-wide text-yellow-500">
															<div className="flex items-center">
																<div className="mr-2 h-[10px] w-[10px] rounded-full bg-yellow-500"></div>
																{item.name}
															</div>
															<span className="text-base font-normal tracking-normal text-white">
																{parseFloat(item.price).toFixed(2)} €
															</span>
														</h3>

														<p className="font-light">{item.description}</p>
													</div>
												</div>
											))
									);
								})}
							</div>
						</>
					)}
					{menuSwitch === 2 && (
						<>
							<h4 className="mt-10 mb-4 text-center text-2xl font-semibold uppercase tracking-wider text-white">
								Menu à {brunchPrice} €
							</h4>
							<div className="mb-8 flex w-full items-center justify-between space-x-10 overflow-x-scroll pb-8 text-white md:mb-16 md:justify-center  md:overflow-x-visible md:pb-0">
								{categorieBrunch[0].categories.map((cat, i) => (
									<button
										key={i}
										className={tab === i ? "activeTab" : "tab"}
										onClick={() => setTab(i)}
									>
										{cat}
									</button>
								))}
							</div>
							<div className="flex flex-col items-start justify-start space-y-6 overflow-x-hidden md:grid md:grid-cols-2 md:justify-items-center md:gap-10 md:gap-x-96 md:space-y-0">
								{categorieBrunch[0].categories.map((cat, i) => {
									return (
										tab === i &&
										brunch
											.filter(x => x.categorie === cat)
											.map(item => (
												<div
													key={item.id}
													className="relative flex w-full items-start justify-between overflow-hidden rounded-xl bg-teal-600/20 p-6 text-white backdrop-blur-md"
													data-aos="fade-left"
												>
													<div className="absolute -right-8 -bottom-8 h-[50px] w-[50px] rotate-45 bg-yellow-500 shadow-lg"></div>
													<div className="w-full">
														<h3 className="mb-1 flex w-full items-center justify-between text-lg font-semibold uppercase tracking-wide text-yellow-500">
															<div className="flex items-center">
																<div className="mr-2 h-[10px] w-[10px] rounded-full bg-yellow-500"></div>
																{item.name}
															</div>
															<span className="text-base font-normal normal-case tracking-normal text-white">
																{item.price}
															</span>
														</h3>

														<p className="font-light">{item.description}</p>
													</div>
												</div>
											))
									);
								})}
							</div>
						</>
					)}
					{menuSwitch === 3 && (
						<>
							<div className="mt-16 mb-8 flex w-full items-center justify-between space-x-10 overflow-x-scroll pb-8 text-white md:mb-16 md:justify-center md:overflow-x-visible md:pb-0">
								{categorieEvenement[0].categories.map((cat, i) => (
									<button
										key={i}
										className={tab === i ? "activeTab" : "tab"}
										onClick={() => setTab(i)}
									>
										{cat}
									</button>
								))}
							</div>
							<div className="flex flex-col items-start justify-start space-y-6 overflow-x-hidden md:grid md:grid-cols-2 md:justify-items-center md:gap-10 md:gap-x-96 md:space-y-0">
								{categorieEvenement[0].categories.map((cat, i) => {
									return (
										tab === i &&
										allSpecialItems
											.filter(x => x.categorie === cat)
											.map(item => (
												<div
													key={item.id}
													className="relative flex w-full items-start justify-between overflow-hidden rounded-xl bg-teal-600/20 p-6 text-white backdrop-blur-md"
													data-aos="fade-left"
												>
													<div className="absolute -right-8 -bottom-8 h-[50px] w-[50px] rotate-45 bg-yellow-500 shadow-lg"></div>
													<div className="w-full">
														<h3 className="mb-1 flex w-full items-center justify-between text-lg font-semibold uppercase tracking-wide text-yellow-500">
															<div className="flex items-center">
																<div className="mr-2 h-[10px] w-[10px] rounded-full bg-yellow-500"></div>
																{item.name}
															</div>
															<span className="text-base font-normal normal-case tracking-normal text-white">
																{item.price}
															</span>
														</h3>

														<p className="font-light">{item.description}</p>
													</div>
												</div>
											))
									);
								})}
							</div>
						</>
					)}
				</div>
				<div className="mt-8 flex w-full flex-col">
					<h1 className="logo mb-10 text-center text-5xl tracking-wider text-yellow-500">
						Menus midi
						<div className="mt-6 flex w-full items-center justify-center space-x-3">
							<div className="h-3 w-3 rounded-full bg-yellow-500"></div>
							<div className="h-3 w-3 rounded-full bg-yellow-500"></div>
							<div className="h-3 w-3 rounded-full bg-yellow-500"></div>
						</div>
					</h1>

					<div className="flex w-full flex-col items-center justify-evenly text-white md:flex-row md:items-start">
						<div className="flex flex-col items-center justify-center">
							<div className="flex flex-col items-center justify-center">
								<h4 className="text-lg">• Entrée et plat au choix •</h4>
								<h5 className="mx-2 text-lg text-yellow-500">ou</h5>
								<h4 className="text-lg">• Plat et dessert au choix •</h4>
							</div>
							<span className="mt-2 text-xl font-medium text-yellow-500">18.00 €</span>
						</div>
						<div className="flex flex-col items-center justify-center">
							<div className="flex flex-col items-center justify-center">
								<h4 className="text-lg">• Entrée au choix •</h4>
								<h4 className="text-lg">• Plat au choix •</h4>
								<h4 className="text-lg">• Dessert au choix •</h4>
							</div>
							<span className="mt-2 text-xl font-medium text-yellow-500">21.00 €</span>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
}
