import { useState, useEffect, useRef } from "react";
import { images } from "../../images";

export default function Gallery() {
	const [zoom, setZoom] = useState(false);
	const [current, setCurrent] = useState(0);

	const length = images.length;

	const next = () => {
		setCurrent(current === length - 1 ? 0 : current + 1);
	};

	const prev = () => {
		setCurrent(current === 0 ? length - 1 : current - 1);
	};

	const ref = useRef();
	const buttonLeft = useRef();
	const buttonRight = useRef();
	const buttonMobileLeft = useRef();
	const buttonMobileRight = useRef();

	const handleClick = e => {
		if (ref.current.contains(e.target)) {
			return;
		}

		if (buttonLeft.current.contains(e.target)) {
			return;
		}

		if (buttonRight.current.contains(e.target)) {
			return;
		}

		if (buttonMobileLeft.current.contains(e.target)) {
			return;
		}

		if (buttonMobileRight.current.contains(e.target)) {
			return;
		}

		setZoom(false);
	};

	useEffect(() => {
		document.addEventListener("mousedown", handleClick);
		return () => {
			document.removeEventListener("mousedown", handleClick);
		};
	}, []);

	if (!Array.isArray(images) || images.length === 0) {
		return null;
	}

	return (
		<section
			id="gallery"
			className="flex w-full flex-col items-center justify-between overflow-x-hidden py-16 pt-32"
		>
			<div className="container mx-auto flex flex-col items-center justify-center">
				<h2 className="flex items-center justify-center text-4xl font-bold capitalize">
					Gallerie photos
				</h2>
				<div className="my-3 flex w-full items-center justify-center space-x-3">
					<div className="h-3 w-3 rounded-full bg-yellow-500"></div>
					<div className="h-3 w-3 rounded-full bg-yellow-500"></div>
					<div className="h-3 w-3 rounded-full bg-yellow-500"></div>
				</div>
				<div className="mt-5 grid w-full grid-cols-2 gap-4 px-4 md:grid-cols-4 lg:grid-cols-6 md:px-0">
					{images.map((img, i) => (
						<div
							onClick={() => {
								setZoom(true);
								setCurrent(i);
							}}
							key={i}
							className="group flex cursor-pointer overflow-hidden rounded-2xl shadow-lg shadow-black/30"
						>
							<img
								className="pointer-events-none transform transition-all duration-300 group-hover:scale-[1.15]"
								src={img}
								alt="BBMK"
							/>
						</div>
					))}
				</div>
				<div
					className={
						zoom
							? " fixed top-0 left-0 z-50 flex h-screen w-full items-center justify-center bg-black/60 opacity-100 transition-all duration-300"
							: " pointer-events-none fixed top-0 left-0 z-50 flex h-screen w-full items-center justify-center bg-black/60 opacity-0 transition-all duration-300"
					}
				>
					<i
						onClick={() => setZoom(false)}
						className="fa-solid fa-xmark absolute top-3 right-5 flex h-[30px] w-[30px] cursor-pointer items-center justify-center rounded-full border-2 border-yellow-500 bg-transparent p-4 text-2xl text-yellow-500 md:h-[40px] md:w-[40px] md:text-3xl"
					></i>
					<div className="flex w-full items-center justify-center px-4 md:px-0">
						<button
							aria-label="Precedente"
							ref={buttonLeft}
							onClick={prev}
							className="mr-4 hidden text-5xl text-white transition-all duration-300 hover:text-yellow-500 md:block"
						>
							<i className="fa-solid fa-circle-chevron-left"></i>
						</button>
						<div className="absolute bottom-0 left-0 flex w-full items-center justify-center md:pointer-events-none md:hidden">
							<button
								aria-label="Precedente"
								ref={buttonMobileLeft}
								onClick={prev}
								className="w-1/2 border-r-2 bg-white p-2 text-xl text-yellow-500"
							>
								<i className="fa-solid fa-circle-chevron-left"></i>
							</button>
							<button
								aria-label="Précédent"
								ref={buttonMobileRight}
								onClick={next}
								className="w-1/2 bg-white p-2 text-xl text-yellow-500"
							>
								<i className="fa-solid fa-circle-chevron-right"></i>
							</button>
						</div>
						{images.map((img, i) => (
							<div
								key={i}
								className="flex h-screen max-w-[100%] items-center justify-center py-5 md:max-w-[80%]"
							>
								{i === current && (
									<img
										ref={ref}
										src={img}
										alt="BBMK"
										className="imgAnimation max-h-full pointer-events-none max-w-[100%]"
									/>
								)}
							</div>
						))}
						<button
							aria-label="Suivant"
							ref={buttonRight}
							onClick={next}
							className="ml-4 hidden text-5xl text-white transition-all duration-300 hover:text-yellow-500 md:block"
						>
							<i className="fa-solid fa-circle-chevron-right"></i>
						</button>
					</div>
				</div>
			</div>
		</section>
	);
}
