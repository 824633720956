import React, { useState } from "react";
import { Link } from "react-scroll";

import { GiKnifeFork } from "react-icons/gi";

import ResPopup from "../Reservation/ResPopup";

export default function Navbar({ setShowRes, showRes, setActive, active }) {
	const [navbar, setNavbar] = useState(false);

	const [toggleNav, setToggleNav] = useState(false);

	const changeBackground = () => {
		if (window.scrollY >= 2) {
			setNavbar(true);
		} else {
			setNavbar(false);
		}
	};

	window.addEventListener("scroll", changeBackground);

	return (
		<>
			<nav
				className={
					navbar
						? "fixed z-50 flex w-full flex-col items-center justify-between bg-teal-900/60 shadow-xl shadow-black/30 py-2 backdrop-blur-md backdrop-filter transition-all duration-300 ease-in-out"
						: "fixed z-50 flex w-full flex-col items-center justify-between bg-teal-900/60 py-2 backdrop-blur-md backdrop-filter transition-all duration-300 ease-in-out md:bg-transparent md:backdrop-blur-none"
				}
			>
				<div className="container mx-auto flex items-center justify-between px-4 text-white lg:px-10 ">
					<div>
						<h1 className="logo text-3xl tracking-widest text-yellow-500 md:text-4xl">
							BBMK
						</h1>
						<span className="text-xs font-semibold tracking-widest md:text-sm">
							RESTAURANT - BAR - HOTEL
						</span>
					</div>

					<div className="hidden items-center justify-center space-x-5 lg:flex">
						<div className="flex items-center justify-center space-x-8 text-sm font-medium">
							<Link
								className="navLinks"
								activeClass="activeLink"
								spy={true}
								duration={800}
								delay={100}
								smooth={true}
								to="home"
							>
								Accueil
							</Link>
							<Link
								className="navLinks"
								activeClass="activeLink"
								spy={true}
								duration={800}
								delay={100}
								smooth={true}
								to="carte"
							>
								La Carte
							</Link>
							<Link
								className="navLinks"
								activeClass="activeLink"
								spy={true}
								duration={800}
								delay={100}
								smooth={true}
								to="about"
							>
								Chambres
							</Link>
							<Link
								className="navLinks"
								activeClass="activeLink"
								spy={true}
								duration={800}
								delay={100}
								smooth={true}
								to="gallery"
							>
								Photos
							</Link>
							<Link
								className="navLinks"
								activeClass="activeLink"
								spy={true}
								duration={800}
								delay={100}
								smooth={true}
								to="avis"
							>
								Les avis
							</Link>
							<Link
								className="navLinks"
								activeClass="activeLink"
								spy={true}
								duration={1100}
								delay={100}
								smooth={true}
								to="contact"
							>
								Contact
							</Link>
						</div>
						<button
							onClick={() => {
								setShowRes(true);
								setActive(0);
							}}
							className="flex items-center rounded-full bg-teal-600 px-6 py-[7px] text-sm font-medium transition-all duration-300 ease-in-out hover:bg-white hover:text-teal-500"
						>
							<GiKnifeFork className="mr-1 text-lg" />
							Réserver
						</button>
						<p>|</p>
						<a
							href="https://www.facebook.com/BBMK91"
							target="_blank"
							rel="noopener noreferrer"
						>
							<i className="fa-brands fa-facebook text-xl text-blue-600"></i>
						</a>
						<a
							href="https://www.instagram.com/bbmk_restaurant/?hl=fr"
							target="_blank"
							rel="noopener noreferrer"
						>
							<i className="fa-brands fa-instagram text-xl text-red-500"></i>
						</a>
					</div>
					<div className="block lg:hidden">
						<button
							onClick={() => setToggleNav(prev => !prev)}
							className={
								toggleNav
									? "rotate-90 transition-all duration-300 ease-in-out"
									: " transition-all duration-300 ease-in-out"
							}
						>
							<div className="flex space-x-2 p-2">
								<div className="h-2 w-2 rounded-full bg-yellow-500"></div>
								<div className="h-2 w-2 rounded-full bg-yellow-500"></div>
								<div className="h-2 w-2 rounded-full bg-yellow-500"></div>
							</div>
							<span
								className={
									toggleNav
										? "text-sm opacity-0 transition-all duration-200"
										: "text-sm opacity-100 transition-all duration-200"
								}
							>
								Menu
							</span>
						</button>
					</div>
				</div>
				<div
					className={
						toggleNav
							? "flex h-[100vh] flex-col items-center justify-center space-y-10 overflow-hidden pt-6 pb-4 text-white opacity-100 transition-all duration-500 ease-in-out lg:hidden"
							: "flex h-[0vh] flex-col items-center justify-center space-y-10 overflow-hidden text-white opacity-0 transition-all duration-500 ease-in-out lg:hidden"
					}
				>
					<Link
						className="navLinks"
						activeClass="activeLink"
						spy={true}
						duration={800}
						delay={100}
						smooth={true}
						onClick={() => setToggleNav(false)}
						to="home"
					>
						Accueil
					</Link>
					<Link
						className="navLinks"
						activeClass="activeLink"
						spy={true}
						duration={800}
						delay={100}
						smooth={true}
						onClick={() => setToggleNav(false)}
						to="carte"
					>
						La Carte
					</Link>
					<Link
						className="navLinks"
						activeClass="activeLink"
						spy={true}
						duration={800}
						delay={100}
						smooth={true}
						onClick={() => setToggleNav(false)}
						to="about"
					>
						Chambres
					</Link>
					<Link
						className="navLinks"
						activeClass="activeLink"
						spy={true}
						duration={800}
						delay={100}
						smooth={true}
						onClick={() => setToggleNav(false)}
						to="gallery"
					>
						Photos
					</Link>
					<Link
						className="navLinks"
						activeClass="activeLink"
						spy={true}
						duration={800}
						delay={100}
						smooth={true}
						onClick={() => setToggleNav(false)}
						to="avis"
					>
						Les avis
					</Link>
					<Link
						className="navLinks"
						activeClass="activeLink"
						spy={true}
						duration={1100}
						delay={100}
						smooth={true}
						onClick={() => setToggleNav(false)}
						to="contact"
					>
						Contact
					</Link>
					<button
						onClick={() => {
							setShowRes(true);
							setToggleNav(false);
						}}
						className="flex items-center rounded-full bg-teal-600 px-6 py-[7px] text-sm font-medium transition-all duration-300 ease-in-out hover:bg-white hover:text-teal-500"
					>
						<GiKnifeFork className="mr-1 text-lg" />
						Réserver
					</button>
					<div className="flex w-full items-center justify-evenly">
						<a
							href="https://www.facebook.com/BBMK91"
							target="_blank"
							rel="noopener noreferrer"
						>
							<i className="fa-brands fa-facebook text-xl text-blue-600"></i>
						</a>
						<a
							href="https://www.instagram.com/bbmk_restaurant/?hl=fr"
							target="_blank"
							rel="noopener noreferrer"
						>
							<i className="fa-brands fa-instagram text-xl text-red-500"></i>
						</a>
					</div>
				</div>
			</nav>
			<ResPopup
				showRes={showRes}
				setShowRes={setShowRes}
				setActive={setActive}
				active={active}
			/>
		</>
	);
}
