import { Link } from "react-router-dom";

export default function Footer() {
	return (
		<footer className="bg-gray-900 w-full">
			<div className="container mx-auto px-4 lg:px-10 py-6 flex flex-col md:flex-row items-start justify-between text-white">
				<div className="self-center md:self-auto w-full md:w-auto flex flex-row md:flex-col items-center justify-between px-4">
					<h1 className="logo text-5xl tracking-widest text-yellow-500 mb-5">
						BBMK
					</h1>
					<div className="flex items-center justify-center space-x-5 text-2xl text-gray-200">
						<a
							href="https://www.facebook.com/BBMK91"
							target="_blank"
							rel="noopener noreferrer"
						>
							<i className="fa-brands fa-facebook"></i>
						</a>
						<a
							href="https://www.instagram.com/bbmk_restaurant/?hl=fr"
							target="_blank"
							rel="noopener noreferrer"
						>
							<i className="fa-brands fa-instagram"></i>
						</a>
					</div>
				</div>
				<div className="w-full md:w-[50%] px-4 mt-5 md:mt-0 flex flex-col md:flex-row items-start space-y-6 md:space-y-0 space-x-0 md:space-x-5">
					<div className="flex flex-col items-start w-[80%]">
						<h3 className="font-bold text-xl mb-3">Contact</h3>
						<div className=" flex items-center justify-between">
							<i className="fa-solid fa-location-dot text-yellow-500 text-lg mr-2"></i>
							<span>
								1 Rue de la Croix Boissée, <br />
								91810 Vert le Grand
							</span>
						</div>
						<div className="my-2 flex items-center justify-between">
							<i className="fa-solid fa-phone text-yellow-500 mr-2"></i>
							<span>01 60 83 10 22</span>
						</div>
						<div className=" flex items-center justify-between">
							<i className="fa-solid fa-envelope text-yellow-500 mr-2"></i>
							<span>contact@bbmk.fr</span>
						</div>
					</div>
					<div className="flex flex-col items-start w-full">
						<h3 className="font-bold text-xl mb-3">
							Horaires Restaurant
						</h3>
						<div className="w-full flex flex-col items-start justify-between space-y-2">
							<div className="w-full flex items-center justify-between">
								<span className="text-yellow-500 font-medium">
									Lundi :
								</span>
								<span>fermé</span>
							</div>
							<div className="w-full flex items-center justify-between">
								<span className="text-yellow-500 font-medium">
									Mardi :
								</span>
								<span>12:00 - 14:00 | fermé le soir</span>
							</div>
							<div className="w-full flex items-center justify-between">
								<span className="text-yellow-500 font-medium">
									Mercredi :
								</span>
								<span>12:00 - 14:00 | fermé le soir</span>
							</div>
							<div className="w-full flex items-center justify-between">
								<span className="text-yellow-500 font-medium">
									Jeudi :
								</span>
								<span>12:00 - 14:00 | 19:00 - 22:00</span>
							</div>
							<div className="w-full flex items-center justify-between">
								<span className="text-yellow-500 font-medium">
									Vendredi :
								</span>
								<span>12:00 - 14:00 | 19:00 - 22:00</span>
							</div>
							<div className="w-full flex items-center justify-between">
								<span className="text-yellow-500 font-medium">
									Samedi :
								</span>
								<span>12:00 - 14:00 | 19:00 - 22:00</span>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="w-full bg-black py-2">
				<div className="container mx-auto flex items-center justify-center text-white py-2 text-sm md:text-base">
					<div className="flex flex-col">
						<p>
							Tout droits réservés © BBMK{" "}
							{new Date().getFullYear()}
						</p>
						<div>
							<Link
								className="underline font-medium text-yellow-500"
								to="/rgpd"
							>
								RGPD
							</Link>{" "}
							-{" "}
							<Link
								className="underline font-medium text-yellow-500"
								to="/cgu"
							>
								CGU et Mentions Légales
							</Link>
						</div>
					</div>
				</div>
			</div>
		</footer>
	);
}
