import React, { useState, useEffect } from "react";

import {
	MouseParallaxContainer,
	MouseParallaxChild,
} from "react-parallax-mouse";

import { onSnapshot, collection, query, where } from "firebase/firestore";
import { ref, listAll, getDownloadURL } from "firebase/storage";
import { storage } from "../../firebase";
import { db } from "../../firebase";

export default function AboutHotel({ setShowRes, setActive }) {
	const [text, setText] = useState();
	const [title, setTitle] = useState("");
	const [paragraph, setParagraph] = useState("");
	const [imageURL, setImageURL] = useState("");

	const imageListRef = ref(storage, "images/hotel/");

	const typography = collection(db, "website_content");
	const q = query(typography, where("position", "==", "Hotel"));

	useEffect(() => {
		const unsub = onSnapshot(q, snapshot => {
			setText(snapshot.docs.map(doc => ({ ...doc.data(), id: doc.id })));
		});

		listAll(imageListRef).then(images => {
			images.items.forEach(item => {
				getDownloadURL(item).then(url => {
					setImageURL(url);
				});
			});
		});

		return unsub;
	}, []);

	useEffect(() => {
		if (text) {
			setTitle(text[0].title);
			setParagraph(text[0].paragraph);
		}
	}, [text]);

	return (
		<section
			id="about"
			className="flex w-full items-center justify-center overflow-x-hidden overflow-y-hidden"
		>
			<div className="container mx-auto my-40 flex flex-col items-center justify-between px-4 md:flex-row lg:px-10">
				<div
					data-aos="fade-right"
					className="relative flex w-full flex-col items-center justify-center text-center md:w-[49%]"
				>
					<span className="logo pointer-events-none absolute -top-16 text-[100px] tracking-wider text-gray-600/5 md:-top-[30%] md:text-[200px]">
						BBMK
					</span>
					<h2 className="flex items-center justify-center text-4xl font-bold capitalize">
						{title}
					</h2>
					<div className="my-6 flex w-full items-center justify-center space-x-3">
						<div className="h-3 w-3 rounded-full bg-yellow-500"></div>
						<div className="h-3 w-3 rounded-full bg-yellow-500"></div>
						<div className="h-3 w-3 rounded-full bg-yellow-500"></div>
					</div>
					<p className=" leading-relaxed">{paragraph}</p>
					<hr className="my-5 h-[5px] w-1/5 bg-yellow-500" />
					<h3 className="mb-3 text-xl font-semibold">Tarifs hotel</h3>
					<div className="flex w-full flex-col items-center justify-center bg-slate-100 p-8 rounded-2xl">
						<p className="flex w-full items-center justify-between border-b border-yellow-500 py-2 text-base">
							<span className="font-medium text-teal-600">
								Nuitée Chambre simple
							</span>
							<span className="font-semibold">65 €</span>
						</p>
						<p className="flex w-full items-center justify-between border-b border-yellow-500 py-2 text-base">
							<span className="font-medium text-teal-600">
								Nuitée Chambre double
							</span>
							<span className="font-semibold">80 €</span>
						</p>
						<p className="flex w-full items-center justify-between border-b border-yellow-500 py-2 text-base">
							<span className="font-medium text-teal-600">
								Nuitée Chambre triple
							</span>
							<span className="font-semibold">100 €</span>
						</p>
						<p className="flex w-full items-center justify-between border-b border-yellow-500 py-2 text-base">
							<span className="font-medium text-teal-600">
								Nuitée Chambre quadruple
							</span>
							<span className="font-semibold">120 €</span>
						</p>
						<p className="flex w-full items-center justify-between py-2 text-base">
							<span className="font-medium text-teal-600">
								Petit déjeuner/personne
							</span>
							<span className="font-semibold">12 €</span>
						</p>
					</div>
					<button
						onClick={() => {
							setShowRes(true);
							setActive(1);
						}}
						className="mt-12 rounded-full border-2 bg-transparent px-9 py-2 text-sm font-semibold uppercase transition-all duration-200 border-teal-600 hover:bg-teal-600 hover:text-white text-teal-600"
					>
						Réserver une chambre
					</button>
				</div>
				<div
					data-aos="fade-left"
					className="mt-14 w-[90%] md:mt-0 md:w-[45%]"
				>
					<MouseParallaxContainer
						containerStyles={{
							position: "relative",
							overflow: "visible",
						}}
						resetOnLeave
					>
						<MouseParallaxChild factorX={0.1} factorY={0.15}>
							<img
								src={imageURL}
								alt="Food"
								className="w-full rounded-3xl pointer-events-none"
							/>
						</MouseParallaxChild>
						<MouseParallaxChild
							className="absolute top-[20px] -left-[20px] -z-[1] h-full w-full rounded-3xl bg-black/5 md:top-[30px] md:-left-[30px]"
							factorX={0.4}
							factorY={0.6}
						></MouseParallaxChild>
						<MouseParallaxChild
							className="absolute -top-[25px] -right-[25px] h-full w-full rounded-3xl  border-[6px] border-yellow-500 bg-transparent"
							factorX={0.15}
							factorY={0.2}
						></MouseParallaxChild>
					</MouseParallaxContainer>
				</div>
			</div>
		</section>
	);
}
