import React from "react";
import { Link } from "react-router-dom";

export default function RGPD() {
	return (
		<section className="px-4 pt-36 md:px-0 container mx-auto min-h-screen flex items-center flex-col justify-center relative">
			<h1 className="text-center logo text-5xl text-yellow-500 mb-8 md:mb-16">
				BBMK
			</h1>
			<Link to="/" className="absolute top-24 left-4 md:left-0 underline hover:text-yellow-500 transition-all duration-300">
				{" "}
				<i className="fa-solid fa-arrow-left mr-2 text-yellow-500"></i>Revenir à l
				'accueil
			</Link>
			<div className="space-y-5 mb-10">
				<h1 className="text-3xl font-semibold">
					Politique de Protection des Données Personnelles
				</h1>
				<p>
					BBMK est engagé dans une démarche continue de conformité
					avec le Règlement général sur la protection des données du
					27 avril 2016. Avec ce nouveau règlement, BBMK renforce sa
					politique de protection de données personnelles afin que les
					données de nos utilisateurs soient collectées et utilisées
					de manière transparente, confidentielle et sécurisée.
				</p>
				<p>
					Notre Politique de protection des données personnelles
					décrit la manière dont BBMK traite les données à caractère
					personnel des visiteurs et des utilisateurs (ci- après les «
					Utilisateurs ») lors de leur navigation sur notre site
					internet (ci-après le « Site »). La Politique de protection
					des données personnelles fait partie intégrante des
					Conditions Générales d’Utilisation du Site.
				</p>
				<p>
					BBMK accorde en permanence une attention aux données de nos
					Utilisateurs. Nous pouvons ainsi être amenés à modifier,
					compléter ou mettre à jour périodiquement la Politique de
					protection des données personnelles. Nous pourrons aussi
					apporter des modifications nécessaires afin de respecter les
					changements de la législation et règlementation en vigueur.
					Dans la mesure du possible, nous vous notifierons tout
					changement important. Nous vous encourageons toutefois à
					consulter règulièrement la dernière version en vigueur,
					accessible sur notre Site.
				</p>
			</div>
			<div className="space-y-5 mb-8 md:mb-0">
				<h1 className="text-3xl font-semibold">
					Quelles données personnelles sont collectées et pour quelles
					finalitées ?
				</h1>
				<p>
					Lorsque vous utilisez les formulaires présents sur notre
					plateforme, nous collectons et traitons des données à
					caractère personnel vous concernant telles que : vos noms et
					prénoms.
				</p>
				<p>
					Nous vous demanderons également de nous transmettre votre
					adresse email et/ou votre numéro de téléphone afin
					d’utiliser ces données pour la réservation de table/chambre
					d'hotel et l’envoi d’emails d’informations.
				</p>
				<p>
					BBMK utilise ces données pour la vente à emporter, qui
					s'effectue sur place, pour l'organisation des réservation
					des tables et des chambres.
				</p>
				<p>
					Lorsque vous nous transmettez vos coordonnées, nous pouvons
					être amenés à vous contacter par téléphone pour confirmer,
					annuler, ou changer votre commande à emporter ou votre
					réservation. Ces données sont utilisées pour
					l’enregistrement, la consultation, l’utilisation, la
					complétion, l’extraction et analyse des statistiques
					mensuelles du restaurant/hotel.
				</p>
			</div>
		</section>
	);
}
