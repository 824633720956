import { useState, useRef } from "react";

import axios from "axios";

import { collection, query, where, addDoc, getDocs } from "firebase/firestore";

import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";

import { db } from "../../firebase";

export default function ResSalle() {
    const [resError, setResError] = useState("");
    const resRef = collection(db, "res_restaurant");

    const [activeResStep, setActiveResStep] = useState(0);

    const [newName, setNewName] = useState("");
    const [newPhone, setNewPhone] = useState("");
    const [newEmail, setNewEmail] = useState("");

    const [newDate, setNewDate] = useState();

    const [dayTime, setDayTime] = useState("");
    const [newTime, setNewTime] = useState("");
    const [dayForTime, setDayForTime] = useState();
    const [sundayTime, setSundayTime] = useState(false);

    const [sentBtn, setSentBtn] = useState(false);

    const childRef = useRef();
    const personRef = useRef();

/*     const offDate1 = new Date("2023-08-08").getDate();
    const offDate2 = new Date("2023-08-09").getDate();
    const offDate3 = new Date("2023-08-10").getDate();
    const offDate4 = new Date("2023-08-11").getDate();
    const offDate5 = new Date("2023-08-12").getDate();
    const offDate6 = new Date("2023-08-13").getDate();
    const offDate7 = new Date("2023-08-14").getDate();
    const offDate8 = new Date("2023-08-15").getDate();
    const offDate9 = new Date("2023-08-16").getDate();
    const offDate10 = new Date("2023-08-17").getDate();
    const offDate11 = new Date("2023-08-18").getDate();
    const offDate12 = new Date("2023-08-19").getDate();
    const offDate13 = new Date("2023-08-20").getDate();
    const offDate14 = new Date("2023-08-21").getDate();

    const offDates = [
        offDate1,
        offDate2,
        offDate3,
        offDate4,
        offDate5,
        offDate6,
        offDate7,
        offDate8,
        offDate9,
        offDate10,
        offDate11,
        offDate12,
        offDate13,
        offDate14,
    ]; */

    var reg = /^([A-Za-z0-9_\-.])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,4})$/;

    const horaireDay = ["12:00", "12:15", "12:30", "12:45", "13:00", "13:15", "13:30", "13:45"];

    /* const horaireSunday = [
        "11:00",
        "11:15",
        "11:30",
        "11:45",
        "12:00",
        "12:15",
        "12:30",
        "12:45",
        "13:00",
        "13:15",
        "13:30",
        "13:45",
        "14:00",
        "14:15",
        "14:30",
        "14:45",
    ]; */

    const horaireNight = [
        "19:00",
        "19:15",
        "19:30",
        "19:45",
        "20:00",
        "20:15",
        "20:30",
        "20:45",
        "21:00",
        "21:15",
        "21:30",
        "21:45",
    ];

    const handleDay = (day, selectedDate) => {
        if (day === "mardi" || day === "mercredi") {
            setDayForTime("dayOnly");
            setSundayTime(false);
            setActiveResStep(1);
        }

        if (day === "jeudi" || day === "vendredi" || day === "samedi") {
            setDayForTime("both");
            setSundayTime(false);
            setActiveResStep(1);
        }

        /* if (day === "dimanche") {
            setDayForTime();
            setSundayTime(true);
        } */

        if (selectedDate === "mercredi 14 février 2024") {
            setDayForTime("both");
            setSundayTime(false);
        }
    };

    const handleNext = () => {
        if (!newDate) {
            return setResError("Date pas valide");
        }

        if (!newTime) {
            return setResError("Horaire pas valide");
        }

        setTimeout(() => {
            setActiveResStep(0);
        }, 200);
    };

    if (newDate) {
        var aa = newDate.substr(newDate.indexOf(" ") + 4);
        var zz = aa.substring(0, aa.lastIndexOf(" "));
        var yearOnly = newDate.split(" ").pop();
    }

    const handleSubmit = async e => {
        e.preventDefault();

        let arr = [];

        const q = query(resRef, where("date", "==", newDate));
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach(doc => {
            arr.push({ ...doc.data(), id: doc.id });
        });

        if (arr) {
            var findDate = arr.filter(x => x.date === newDate);
            var halfDay = findDate.filter(x => x.jour === dayTime);
        }

        var everyone = halfDay.reduce((prev, curr) => {
            return prev + +curr.placeOccupe;
        }, 0);

        if (everyone >= 50) {
            return setResError("Complet pour le " + dayTime);
        }

        if (+everyone + +personRef.current.value + +childRef.current.value >= 50) {
            return setResError("Complet pour le " + dayTime);
        }

        if (newName.length <= 3) {
            return setResError("Nom pas valide");
        }

        if (personRef.current.value === 0) {
            return setResError("Nombre de personnes pas valide");
        }

        if (newPhone.length <= 8) {
            return setResError("Téléphone pas valide");
        }

        if (reg.test(newEmail) === false) {
            return setResError("Email pas valide");
        }

        if (!newDate) {
            return setResError("Date pas valide");
        }

        if (!newTime) {
            return setResError("Horaire pas valide");
        }

        var adultes = personRef.current.value;
        var children = childRef.current.value;
        var total;

        if ((+adultes + +children) % 2 === 0) {
            total = +adultes + +children;
        } else {
            total = +adultes + +children + 1;
        }

        const newOptions = {
            year: "numeric",
            month: "long",
            day: "2-digit",
        };

        try {
            await addDoc(resRef, {
                name: newName.toLowerCase(),
                adultes: personRef.current.value,
                enfant: childRef.current.value,
                placeOccupe: total,
                table: 0,
                phone: newPhone,
                email: newEmail,
                jour: dayTime,
                date: newDate,
                horaire: newTime,
                dateResa: new Date().toLocaleDateString("fr-FR", newOptions),
                heureResa: new Date().getHours() + ":" + new Date().getMinutes(),
                mois: zz,
                year: yearOnly,
            });
            setSentBtn(true);
            setActiveResStep(0);
            setNewTime();
            setNewDate();
            e.target.reset();
        } catch (error) {
            console.log(error);
            setResError("Backend error");
        }

        try {
            await axios.post("https://bbmk.herokuapp.com/reservation", {
                newName,
                newEmail,
                newPhone,
                newDate,
                newTime,
                adultes,
                children,
            });
        } catch (error) {
            console.log(error);
        }
    };

    if (sentBtn) {
        setTimeout(() => {
            setSentBtn(false);
        }, 4000);
    }

    if (resError.length >= 2) {
        setTimeout(() => {
            setResError("");
        }, 4000);
    }

    const options = {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "2-digit",
    };

    return (
        <>
            <form
                onSubmit={handleSubmit}
                className={
                    sentBtn
                        ? "relative -z-10 max-h-[0px] w-full overflow-hidden opacity-0 transition-all duration-500 ease-in-out md:max-h-[0px]"
                        : "relative z-10 h-screen w-full overflow-y-auto opacity-100 transition-all duration-500 ease-in-out md:h-auto md:max-h-[700px]"
                }
            >
                {activeResStep === 1 ? (
                    <button
                        onClick={() => setActiveResStep(0)}
                        className="mb-3 rounded-lg bg-slate-200 p-2 px-3 font-medium transition-all duration-300 hover:bg-slate-300"
                    >
                        <i className="fa-solid fa-arrow-left mr-2 text-teal-500"></i>
                        Changez la date
                    </button>
                ) : (
                    ""
                )}
                {activeResStep === 2 ? (
                    <button
                        onClick={() => setActiveResStep(1)}
                        className="mb-3 rounded-lg bg-slate-200 p-2 px-3 font-medium transition-all duration-300 hover:bg-slate-300"
                    >
                        <i className="fa-solid fa-arrow-left mr-2 text-teal-500"></i>
                        Changez l'heure
                    </button>
                ) : (
                    ""
                )}

                <div
                    className={
                        activeResStep === 1
                            ? "flex max-h-[350px] w-full flex-col items-center justify-center overflow-hidden opacity-100 transition-all duration-500 ease-in-out"
                            : "flex max-h-[0px] w-full flex-col items-center justify-center overflow-hidden opacity-0 transition-all duration-500 ease-in-out"
                    }
                >
                    <div className="mt-5 flex w-full flex-col overflow-hidden transition-all duration-300 ease-in-out">
                        <div
                            className={
                                dayForTime === "dayOnly" || dayForTime === "both"
                                    ? "h-[140px] overflow-hidden transition-all duration-300 ease-in-out"
                                    : "h-[0px] overflow-hidden transition-all duration-300 ease-in-out"
                            }
                        >
                            <h3 className="mb-3 font-medium">Midi</h3>
                            <div className="grid w-full grid-cols-4 gap-2">
                                {horaireDay.map(time => (
                                    <button
                                        key={time}
                                        onClick={e => {
                                            setNewTime(e.target.innerText);
                                            setDayTime("midi");
                                            setActiveResStep(2);
                                        }}
                                        type="button"
                                        className={
                                            newTime === time
                                                ? "rounded-full bg-teal-600 py-2 font-semibold text-white transition-all duration-200"
                                                : "rounded-full bg-slate-100 py-2 font-semibold transition-all duration-300 hover:bg-slate-300"
                                        }
                                    >
                                        {time}
                                    </button>
                                ))}
                            </div>
                        </div>
                        <div
                            className={
                                dayForTime === "both"
                                    ? "h-[200px] overflow-hidden transition-all duration-300 ease-in-out"
                                    : "h-[0px] overflow-hidden transition-all duration-300 ease-in-out"
                            }
                        >
                            <h3 className="my-3 font-medium">Soir</h3>
                            <div className="grid w-full grid-cols-4 gap-2">
                                {horaireNight.map(time => (
                                    <button
                                        key={time}
                                        onClick={e => {
                                            setNewTime(e.target.innerText);
                                            setDayTime("soir");
                                            setActiveResStep(2);
                                        }}
                                        type="button"
                                        className={
                                            newTime === time
                                                ? "rounded-full bg-teal-600 py-2 font-semibold text-white transition-all duration-200"
                                                : "rounded-full bg-slate-100 py-2 font-semibold transition-all duration-300 hover:bg-slate-300"
                                        }
                                    >
                                        {time}
                                    </button>
                                ))}
                            </div>
                        </div>
                        {/* <div
                            className={
                                sundayTime
                                    ? "h-[200px] overflow-hidden transition-all duration-300 ease-in-out"
                                    : "h-[0px] overflow-hidden transition-all duration-300 ease-in-out"
                            }
                        >
                            <h3 className="my-3 font-medium">Midi</h3>
                            <div className="grid w-full grid-cols-6 gap-2">
                                {horaireSunday.map(time => (
                                    <button
                                        key={time}
                                        onClick={e => {
                                            setNewTime(e.target.innerText);
                                            setDayTime("midi");
                                        }}
                                        type="button"
                                        className={
                                            newTime === time
                                                ? "rounded-lg bg-teal-600 py-2 font-semibold text-white transition-all duration-200"
                                                : "rounded-lg bg-slate-200 py-2 font-semibold transition-all duration-300"
                                        }
                                    >
                                        {time}
                                    </button>
                                ))}
                            </div>
                        </div> */}
                    </div>
                </div>

                <div
                    className={
                        activeResStep === 2
                            ? "flex h-[300px] w-full flex-col items-center justify-center overflow-hidden opacity-100 transition-all duration-500 ease-in-out"
                            : "flex h-[0px] w-full flex-col items-center justify-center overflow-hidden opacity-0 transition-all duration-500 ease-in-out"
                    }
                >
                    <input
                        type="text"
                        placeholder="Nom"
                        onChange={e => {
                            setNewName(e.target.value);
                        }}
                        required
                        className="mb-3 w-full rounded-xl border-2 border-slate-300 p-3"
                    />

                    <div className="flex w-full items-center justify-between">
                        <input
                            type="number"
                            placeholder="Adultes"
                            min={1}
                            max={50}
                            ref={personRef}
                            required
                            className="my-3 w-[49%] rounded-xl border-2 border-slate-300 p-3"
                        />
                        <input
                            type="number"
                            min={0}
                            max={10}
                            placeholder="Enfants"
                            ref={childRef}
                            required
                            className="my-3 w-[49%] rounded-xl border-2 border-slate-300 p-3"
                        />
                    </div>

                    <input
                        type="tel"
                        placeholder="Téléphone"
                        onChange={e => {
                            setNewPhone(e.target.value);
                        }}
                        required
                        className="my-3 w-full rounded-xl border-2 border-slate-300 p-3"
                    />
                    <input
                        type="email"
                        placeholder="Email"
                        onChange={e => {
                            setNewEmail(e.target.value);
                        }}
                        required
                        className="mt-3 w-full rounded-xl border-2 border-slate-300 p-3"
                    />
                </div>

                <div
                    className={
                        activeResStep >= 1
                            ? "max-h-[0px] w-full overflow-hidden opacity-0 transition-all duration-500 ease-in-out"
                            : "max-h-[650px] w-full overflow-hidden opacity-100 transition-all duration-500 ease-in-out"
                    }
                >
                    <div className="flex w-full items-center justify-center">
                        <div className="w-[100%]">
                            <Calendar
                                minDate={new Date()}
                                locale="fr-FR"
                                tileDisabled={({ date }) =>
                                    date.getDay() === 1 ||
                                    date.getDay() === 0 /* ||
                                    offDates.includes(date.getDate()) */
                                }
                                onChange={date => setNewDate(date.toLocaleDateString("fr-FR", options))}
                                onClickDay={date =>
                                    handleDay(
                                        date.toLocaleDateString("fr-FR", options).split(" ")[0],
                                        date.toLocaleDateString("fr-FR", options)
                                    )
                                }
                            />
                        </div>
                    </div>
                </div>

                {activeResStep === 2 && (
                    <button
                        type="submit"
                        className={
                            resError === ""
                                ? "mt-5 w-full transform rounded-full bg-teal-600 p-3 text-sm font-medium uppercase tracking-wider text-white transition-all duration-300 ease-in-out hover:-translate-y-2 hover:bg-teal-700"
                                : "mt-5 w-full transform cursor-not-allowed rounded-full bg-red-500 p-3 text-sm font-semibold uppercase tracking-wide text-white transition-all duration-300 ease-in-out"
                        }
                    >
                        {resError === "" ? "réserver" : <span>{resError}</span>}
                    </button>
                )}
            </form>
            <div
                className={
                    sentBtn
                        ? "visible-check flex h-[250px] flex-col items-center justify-center opacity-100 transition-all duration-300 ease-in-out"
                        : "flex h-[0px] flex-col items-center justify-center overflow-hidden opacity-0 transition-all duration-300 ease-in-out"
                }
            >
                <svg className="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                    <circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
                    <path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
                </svg>
                <h4 className="my-7 text-2xl font-medium">Réservation effectuée!</h4>
            </div>
        </>
    );
}
