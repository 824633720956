import { signInWithEmailAndPassword, onAuthStateChanged } from "firebase/auth";
import React, { useContext, useState } from "react";

import { authContext } from "../../Context/AuthContext";
import { auth } from "../../firebase";
import Mobiletoggle from "./Mobiletoggle";

export default function Login() {
	const [loginEmail, setLoginEmail] = useState("");
	const [loginPassword, setLoginPassword] = useState("");
	const { setUser } = useContext(authContext);
	const [error, setError] = useState("");
	onAuthStateChanged(auth, currentUser => {
		setUser(currentUser);
	});

	const login = async e => {
		e.preventDefault();

		if (!loginEmail) {
			return setError("Email pas valide");
		}

		if (!loginPassword) {
			return setError("Password pas valide");
		}

		try {
			await signInWithEmailAndPassword(auth, loginEmail, loginPassword);
		} catch (error) {
			console.log(error);
			setError("Erreur Server");
		}
	};

	if (error.length >= 2) {
		setTimeout(() => {
			setError("");
		}, 4000);
	}

	return (
		<div className="flex h-screen w-full items-center justify-between bg-slate-100 dark:bg-slate-800">
			<div className="flex h-full w-full flex-col items-center justify-between bg-white shadow-2xl dark:bg-slate-600 lg:w-[40%] xl:w-[28%]">
				<h1 className="logo my-10 text-5xl text-yellow-500">BBMK</h1>
				<form
					onSubmit={login}
					className="flex w-full flex-col items-center justify-center p-12 dark:border-yellow-500"
				>
					<h1 className="mb-7 w-full text-left text-3xl font-semibold text-teal-600">
						Bienvenue dans votre <br /> espace admin 👋
					</h1>
					<div className="w-full space-y-5">
						<input
							onChange={e => setLoginEmail(e.target.value)}
							type="email"
							placeholder="Email"
							required
							className="w-full rounded-md border bg-slate-50 p-3 text-sm transition-all duration-200 focus:border-teal-600 dark:border-slate-500 dark:bg-slate-700 dark:text-white"
						/>
						<input
							onChange={e => setLoginPassword(e.target.value)}
							type="password"
							placeholder="Mot de passe"
							required
							className="w-full rounded-md border bg-slate-50 p-3 text-sm transition-all duration-200 focus:border-teal-600 dark:border-slate-500 dark:bg-slate-700 dark:text-white"
						/>
					</div>
					<button
						type="submit"
						className="mt-8 w-full rounded-xl bg-yellow-500 p-3 text-sm font-bold uppercase tracking-wider text-slate-600 shadow-lg shadow-yellow-400 transition-all duration-200 hover:shadow-none dark:shadow-yellow-600"
					>
						Login
					</button>
					{!error ? "" : <span className="text-red-500">{error}.</span>}
				</form>
				<div className="flex w-full items-center justify-between bg-slate-200 px-8 py-5 dark:bg-slate-700">
					<Mobiletoggle />
					<div className="text-sm font-semibold text-teal-600 dark:text-teal-400">Version 31.01.2023</div>
				</div>
			</div>
			<div className="relative hidden h-full items-center justify-center lg:flex lg:w-[60%] xl:w-[72%]">
				<h1 className="logo absolute top-0 right-12 my-10 text-[4.5rem] text-teal-600 dark:text-yellow-500">
					BBMK
				</h1>
				<img src="/img/login.svg" alt="Login" className="pointer-events-none h-full w-full" />
			</div>
		</div>
	);
}
