import { useEffect, useState, useRef } from "react";

import axios from "axios";

export default function Reply({ id, showReply, setShowReply, db, doc, updateDoc, email }) {
	const [reply, setReply] = useState("");
	const [success, setSuccess] = useState(false);

	const ref = useRef();
	const handleClick = e => {
		if (ref.current.contains(e.target)) {
			// inside click
			return;
		}
		// outside click
		setShowReply(false);
	};
	useEffect(() => {
		// add when mounted
		document.addEventListener("mousedown", handleClick);
		// return function to be called when unmounted
		return () => {
			document.removeEventListener("mousedown", handleClick);
		};
	}, []);

	const handleSubmit = async e => {
		e.preventDefault();

		const chosenDoc = doc(db, "avis", id);
		try {
			await updateDoc(chosenDoc, { reply: reply });
			setSuccess(true);
			e.target.reset();
		} catch (error) {
			console.log(error);
		}

		try {
			await axios.post("https://bbmk.herokuapp.com/sendReply", {
				reply: reply,
				email: email,
			});
		} catch (error) {
			console.log(error);
		}
	};

	if (success) {
		setTimeout(() => {
			setSuccess(false);
		}, 3000);
	}

	return (
		<div
			className={
				showReply
					? "fixed top-0 left-0 z-50 flex h-full w-full items-center justify-center bg-black/40 opacity-100 transition-all duration-300"
					: "pointer-events-none fixed top-0 left-0 z-50 flex h-full w-full items-center justify-center bg-black/40 opacity-0 transition-all duration-300"
			}
		>
			<div
				ref={ref}
				className="flex w-1/2 flex-col items-start justify-start rounded-2xl bg-white p-8 shadow-lg dark:bg-gray-600"
			>
				<h1 className="mb-4 flex w-full items-center justify-between text-3xl font-bold text-teal-500">
					Répondre
					<i onClick={() => setShowReply(false)} className="fa-solid fa-circle-xmark cursor-pointer"></i>
				</h1>
				<form onSubmit={handleSubmit} className="flex w-full flex-col items-start justify-start">
					<div className="flex w-full flex-col items-start">
						<label className="mb-2 font-medium text-gray-700 dark:text-white">Réponse à {email}</label>
						<textarea
							className="h-64 w-full rounded-md border-2 p-3 dark:border-slate-500 dark:bg-gray-700 dark:text-white"
							value={reply}
							required
							onChange={e => setReply(e.target.value)}
						/>
					</div>
					<button
						type="submit"
						className={
							success
								? "mt-6 w-full rounded-full bg-green-500 p-3 text-sm font-medium text-white"
								: "mt-6 w-full rounded-full bg-yellow-500 p-3 text-sm font-semibold text-black shadow-lg shadow-yellow-400 transition-all duration-200 hover:shadow-none dark:shadow-yellow-600"
						}
					>
						{success ? "Réponse envoyée" : "Envoyer"}
					</button>
				</form>
			</div>
		</div>
	);
}
