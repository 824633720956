import { useEffect, useRef } from "react";

import ResSalle from "./ResSalle";
import ResChambre from "./ResChambre";

export default function ResPopup({ setShowRes, showRes, active, setActive }) {
    const ref = useRef();

    const handleClick = e => {
        if (ref.current.contains(e.target)) {
            return;
        }
        setShowRes(false);
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClick);
        return () => {
            document.removeEventListener("mousedown", handleClick);
        };
    }, []); //eslint-disable-line

    useEffect(() => {
        if (showRes) {
            document.body.style.overflowY = "hidden";
        } else {
            document.body.style.overflowY = "auto";
        }
    }, [showRes]);

    return (
        <div
            className={
                showRes
                    ? "fixed top-0 left-0 z-50 min-h-screen w-screen items-center justify-center bg-black/50  opacity-100 backdrop-blur-md backdrop-filter transition-all duration-300 ease-in-out md:flex"
                    : "pointer-events-none fixed top-0 left-0 z-50 min-h-screen w-screen items-center justify-center  bg-black/50 opacity-0 backdrop-blur-none backdrop-filter transition-all duration-300 ease-in-out md:flex"
            }
        >
            <div
                ref={ref}
                className="flex max-h-screen w-full flex-col items-center justify-start overflow-hidden bg-white p-6 shadow-xl md:w-[500px] md:justify-center md:rounded-xl md:p-9"
            >
                <h1 className="flex w-full items-center justify-between self-start text-3xl font-semibold">
                    Réservation
                    <button type="button" onClick={() => setShowRes(false)} className="hover:text-teal-600">
                        <i className="fas fa-times"></i>
                    </button>
                </h1>

                <div className="relative my-5 mt-7 flex w-full items-center justify-between overflow-hidden rounded-full bg-slate-200 p-2">
                    <div
                        className={`absolute h-full w-1/2 rounded-full ${
                            active === 0 ? "left-0" : "left-[50%]"
                        } bg-teal-600 transition-all duration-300`}
                    />
                    <button
                        onClick={() => setActive(0)}
                        className={
                            active === 0
                                ? "relative w-1/2 rounded-full px-4 py-2 font-semibold text-yellow-400 transition-all duration-300"
                                : "relative w-1/2 rounded-full px-4 py-2 font-semibold text-slate-600 transition-all duration-300 hover:text-teal-600"
                        }
                    >
                        <i className="fa-solid fa-utensils"></i> Restaurant
                    </button>
                    <button
                        onClick={() => setActive(1)}
                        className={
                            active === 1
                                ? "relative w-1/2 rounded-full px-4 py-2 font-semibold text-yellow-400 transition-all duration-300"
                                : "relative w-1/2 rounded-full px-4 py-2 font-semibold text-slate-600 transition-all duration-300 hover:text-teal-600"
                        }
                    >
                        <i className="fa-solid fa-bed"></i> Hotel
                    </button>
                </div>

                <div
                    className={
                        active === 1
                            ? "mb-3 flex w-full items-center justify-around opacity-100 transition-all duration-300"
                            : "mb-3 flex w-full items-center justify-around opacity-0 transition-all duration-300"
                    }
                >
                    <p className="flex items-center">
                        <i className="fa-solid fa-arrow-right mr-2  text-yellow-500"></i>
                        <span className="font-medium">Check-in : 16:00</span>
                    </p>
                    <p className="flex items-center">
                        <i className="fa-solid fa-arrow-left  mr-2 text-yellow-500"></i>
                        <span className="font-medium">Check-out : 11:00</span>
                    </p>
                </div>

                {active === 0 && <ResSalle />}
                {active === 1 && <ResChambre />}
            </div>
        </div>
    );
}
