import React, { useState, useEffect } from "react";

import { onSnapshot, collection, query, where, updateDoc, doc } from "firebase/firestore";
import { db } from "../../../firebase";

export default function Text() {
	const [text, setText] = useState([]);
	const [title, setTitle] = useState("");
	const [paragraph, setParagraph] = useState("");
	const [position, setPosition] = useState();

	const [ID, setID] = useState("");

	const [updated, setUpdated] = useState(false);

	const typography = collection(db, "website_content");
	const q = query(typography, where("type", "==", "text"));

	useEffect(() => {
		const unsub = onSnapshot(q, snapshot => {
			setText(snapshot.docs.map(doc => ({ ...doc.data(), id: doc.id })));
		});

		return unsub;
	}, []);

	useEffect(() => {
		if (ID) {
			var z = text.filter(x => x.id === ID);
			setTitle(z[0].title);
			setParagraph(z[0].paragraph);
			setPosition(z[0].position);
		}
	}, [ID]);

	const handleSubmit = async e => {
		e.preventDefault();

		const chosenDoc = doc(db, "website_content", ID);

		await updateDoc(chosenDoc, {
			position: position,
			title: title,
			paragraph: paragraph,
		});

		e.target.reset();
		setUpdated(true);
	};

	if (updated) {
		setTimeout(() => {
			setUpdated(false);
		}, 3000);
	}

	return (
		<div className="flex w-full flex-col items-start justify-between p-6">
			<form onSubmit={handleSubmit}>
				<select
					name="para"
					defaultValue="Choisir"
					onChange={e => setID(e.target.value)}
					id="para"
					className="my-2 w-full cursor-pointer rounded-lg border bg-white p-3 text-sm capitalize outline-none dark:border-slate-500 dark:bg-gray-700 dark:text-white"
				>
					<option value="Choisir" disabled>
						Choissisez le paragraphe
					</option>
					{text.map(x => (
						<option value={x.id}>{x.position}</option>
					))}
				</select>
				<input
					value={title}
					onChange={e => setTitle(e.target.value)}
					type="text"
					placeholder="Titre"
					required
					className="my-2 w-full rounded-lg border bg-white p-3 text-sm capitalize dark:border-slate-500 dark:bg-gray-700 dark:text-white"
				/>
				<textarea
					value={paragraph}
					onChange={e => setParagraph(e.target.value)}
					placeholder="Paragraphe"
					required
					className="my-2 h-[300px] w-full rounded-lg border bg-white p-3 text-sm dark:border-slate-500 dark:bg-gray-700 dark:text-white"
				/>
				<button
					type="submit"
					className={
						updated
							? "mt-4 w-full rounded-full bg-green-600 p-3 text-sm font-medium"
							: "mt-4 w-full rounded-full bg-yellow-500 p-3 text-sm font-medium"
					}
				>
					{updated ? "Paragraphe bien mis à jour" : "Mettre à jour"}
				</button>
			</form>
		</div>
	);
}
