import React, { useState, useRef, useEffect } from "react";

import { updateDoc, doc } from "firebase/firestore";
import { db } from "../../../firebase";

import axios from "axios";

import { BiMailSend } from "react-icons/bi";
import { MdDeleteForever } from "react-icons/md";

export default function RestaurantBtn({ customer, onDelete }) {
	const [sentAvis, setSentAvis] = useState(false);
	const [adultes, setAdultes] = useState();
	const [enf, setEnf] = useState();
	const [table, setTable] = useState();
	const [id, setId] = useState();

	const [adulteAdded, setAdulteAdded] = useState(false);
	const [enfAdded, setEnfAdded] = useState(false);

	const emailRef = useRef();

	const [tableAdded, setTableAdded] = useState(false);
	const addTable = async e => {
		e.preventDefault();

		const chosenDoc = doc(db, "res_restaurant", id);
		await updateDoc(chosenDoc, {
			table: parseInt(table),
		});

		setTableAdded(true);
	};

	const changeAdultes = async e => {
		e.preventDefault();

		const chosenDoc = doc(db, "res_restaurant", id);
		await updateDoc(chosenDoc, {
			adultes: parseInt(adultes),
		});

		setAdulteAdded(true);
	};

	const changeEnf = async e => {
		e.preventDefault();

		const chosenDoc = doc(db, "res_restaurant", id);
		await updateDoc(chosenDoc, {
			enfant: parseInt(enf),
		});

		setEnfAdded(true);
	};

	useEffect(() => {
		setTable(parseInt(customer.table));
	}, [customer.table]);

	useEffect(() => {
		setAdultes(parseInt(customer.adultes));
	}, [customer.adultes]);

	useEffect(() => {
		setEnf(parseInt(customer.enfant));
	}, [customer.enfant]);

	useEffect(() => {
		setSentAvis(customer.avisSent);
	}, [customer.avisSent]);

	useEffect(() => {
		setId(customer.id);
	}, [customer.id]);

	const sendEmail = async e => {
		e.preventDefault();

		var email = emailRef.current.value;

		try {
			await axios.post("https://bbmk.herokuapp.com/avis", {
				email,
			});

			const chosenDoc = doc(db, "res_restaurant", id);
			await updateDoc(chosenDoc, {
				avisSent: true,
			});
		} catch (error) {
			console.log(error);
		}

		setSentAvis(true);
	};

	if (tableAdded) {
		setTimeout(() => {
			setTableAdded(false);
		}, 3000);
	}

	if (enfAdded) {
		setTimeout(() => {
			setEnfAdded(false);
		}, 3000);
	}

	if (adulteAdded) {
		setTimeout(() => {
			setAdulteAdded(false);
		}, 3000);
	}

	return (
		<div
			key={customer.id}
			className="inline-grid h-auto w-[1200px] grid-cols-6 items-center justify-items-start gap-0 border-b border-gray-400 p-3 text-sm dark:text-white 2xl:w-full"
		>
			<div className="flex w-fit items-center ">
				<h5 className="font-bold">Client : </h5>
				<p className="ml-2 capitalize">{customer.name}</p>
			</div>
			<form onSubmit={changeAdultes} className="flex w-fit items-center justify-center">
				<h5 className="font-bold text-yellow-500">Adultes : </h5>
				<input
					min={0}
					value={adultes}
					onChange={e => {
						setAdultes(e.target.value);
					}}
					required
					type="number"
					placeholder="Table"
					className="ml-2 w-1/5 rounded bg-gray-100 p-1 px-2 capitalize dark:bg-gray-700 dark:text-white"
				/>
				<div className="relative flex items-center justify-center">
					{adulteAdded ? (
						<span className="absolute -top-10 w-[120px] rounded bg-gray-200 text-center text-black">
							Modifié <i className="fa-solid fa-circle-check text-green-500"></i>
						</span>
					) : (
						""
					)}
					<button type="submit" className="ml-2 rounded bg-green-600 p-1 px-2 capitalize text-white">
						<i className="fa-solid fa-clipboard-check"></i>
					</button>
				</div>
			</form>
			<form onSubmit={changeEnf} className="flex w-fit items-center justify-center">
				<h5 className="font-bold text-yellow-500">Enfants : </h5>
				<input
					min={0}
					value={enf}
					onChange={e => {
						setEnf(e.target.value);
					}}
					required
					type="number"
					placeholder="Table"
					className="ml-2 w-1/5 rounded bg-gray-100 p-1 px-2 capitalize dark:bg-gray-700 dark:text-white"
				/>
				<div className="relative flex items-center justify-center">
					{enfAdded ? (
						<span className="absolute -top-10 w-[120px] rounded bg-gray-200 text-center text-black">
							Modifié <i className="fa-solid fa-circle-check text-green-500"></i>
						</span>
					) : (
						""
					)}
					<button type="submit" className="ml-2 rounded bg-green-600 p-1 px-2 capitalize text-white">
						<i className="fa-solid fa-clipboard-check"></i>
					</button>
				</div>
			</form>
			<div className="flex w-fit items-center justify-center">
				<h5 className="font-bold">Réservation : </h5>
				<p className="ml-2 ">{customer.horaire}</p>
			</div>
			<form onSubmit={addTable} className="flex w-fit items-center justify-center ">
				<h5 className="font-bold text-yellow-500">Table : </h5>
				<input
					min={0}
					value={table}
					onChange={e => {
						setTable(e.target.value);
					}}
					required
					type="number"
					placeholder="Table"
					className="ml-2 w-[40px] rounded bg-gray-100 p-1 px-2 capitalize dark:bg-gray-700 dark:text-white"
				/>
				<div className="relative flex items-center justify-center">
					{tableAdded ? (
						<span className="absolute -top-10 w-[120px] rounded bg-gray-200 text-center text-black">
							Ajouté <i className="fa-solid fa-circle-check text-green-500"></i>
						</span>
					) : (
						""
					)}
					<button type="submit" className="ml-2 rounded bg-green-600 p-1 px-2 capitalize text-white">
						<i className="fa-solid fa-clipboard-check"></i>
					</button>
				</div>
			</form>
			<div className="flex items-center justify-end justify-self-end">
				<form onSubmit={sendEmail}>
					<input ref={emailRef} type="text" className="hidden" value={customer.email} name="user_email" />
					<button
						type="submit"
						disabled={sentAvis}
						className={
							sentAvis
								? "cursor-not-allowed border border-transparent bg-transparent p-1 transition-all duration-200 hover:bg-transparent"
								: "border border-transparent p-1 transition-all duration-200  hover:bg-transparent hover:text-yellow-500"
						}
					>
						{sentAvis ? (
							<span className="rounded-lg bg-green-600 p-1 px-2">
								<i className="fa-solid fa-circle-check text-white"></i> Avis envoyé
							</span>
						) : (
							<BiMailSend className="text-2xl" />
						)}
					</button>
				</form>
				<button
					onClick={() => onDelete(customer.id)}
					className="ml-3 border border-transparent p-1 transition-all duration-200 hover:bg-transparent hover:text-red-600"
				>
					<MdDeleteForever className="text-2xl" />
				</button>
			</div>
		</div>
	);
}
