import React, { useEffect, useState, useMemo } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import { authContext } from "./Context/AuthContext";

import Aos from "aos";
import "aos/dist/aos.css";

import { keepTheme } from "./Utils/theme";

import Navbar from "./Components/Navbar/Navbar";
import Home from "./Components/Home/Home";
import About from "./Components/About/About";
import AboutHotel from "./Components/AboutHotel/AboutHotel";
import Restaurant from "./Components/Restaurant/Restaurant";
import Gallery from "./Components/Gallery/Gallery";
import Avis from "./Components/Avis/Avis";
import Contact from "./Components/Contact/Contact";

import Footer from "./Components/Footer/Footer";
import Rating from "./Components/Avis/Rating";

import Loader from "./Components/Loader/Loader";

import Main from "./Components/Admin/Main";
import ProtectedRoute from "./ProtectedRoute";
import RGPD from "./Components/Legal/RGPD";
import CGU from "./Components/Legal/CGU";

function App() {
    const [load, setLoad] = useState(true);
    const [showRes, setShowRes] = useState(false);
    const [active, setActive] = useState(0);

    const [user, setUser] = useState();
    const providerValue = useMemo(() => ({ user, setUser }), [user, setUser]);

    setTimeout(() => {
        setLoad(false);
    }, 1500);

    useEffect(() => {
        Aos.init({ duration: 2000, once: true });
    }, []);

    useEffect(() => {
        keepTheme();
    });

    return (
        <authContext.Provider value={providerValue}>
            <BrowserRouter>
                <Routes>
                    <Route
                        exact
                        path="/"
                        element={
                            <>
                                <Navbar
                                    setShowRes={setShowRes}
                                    showRes={showRes}
                                    setActive={setActive}
                                    active={active}
                                />
                                <Home setShowRes={setShowRes} showRes={showRes} setActive={setActive} />
                                <About />
                                <Restaurant />
                                <AboutHotel setShowRes={setShowRes} showRes={showRes} setActive={setActive} />
                                <Gallery />
                                <Avis />
                                <Contact />
                                <Footer />
                            </>
                        }
                    />
                    {/* 				<Route
					path="/emporter"
					element={
						<>
							<Emporter />
							<Footer />
						</>
					}
				/> */}
                    <Route exact path="/avis" element={<Rating />} />
                    <Route exact path="/rgpd" element={<RGPD />} />
                    <Route exact path="/cgu" element={<CGU />} />
                    <Route element={<ProtectedRoute />}>
                        <Route path="/admin/*" element={<Main />}></Route>
                    </Route>
                </Routes>
            </BrowserRouter>
        </authContext.Provider>
    );
}

export default App;
